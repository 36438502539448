<div class="right-nav">
  <div class="card">
    <div class="card-header">
      <div class="float-left">Order Amount</div>
      <div class="float-right">
        <div id="dealInner">
          <i class="fa fa-angle-down fa-3" aria-hidden="true"></i>
        </div>
        <div id="close">
          <i class="fa fa-angle-up fa-3" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="card-body">
      <table class="table table-bordered">
        <colgroup>
          <col style="width: 30%;">
          <col style="width: 20%;">
          <col style="width: 20%;">
          <col style="width: 20%;">
        </colgroup>
        <tbody>
          <tr>
            <th>Item</th>
            <th class="text-right">Qty</th>
            <th class="text-right">Rate</th>
            <th class="text-right">Amount</th>
          </tr>
          <tr *ngIf="lensePriceRight && lensePriceRight > 0">
            <td>Lens Right</td>
            <td class="text-right">{{displayRightQuantity}}</td>
            <td class="text-right">
              {{ lensePriceRight }}
            </td>
            <td class="text-right">{{lensePriceRight*rightQuantity}}</td>
          </tr>
          <tr *ngIf="lensePriceLeft && lensePriceLeft > 0">
            <td>Lens Left</td>
            <td class="text-right">{{displayLeftQuantity}}</td>
            <td class="text-right">
              {{ lensePriceLeft }}
            </td>
            <td class="text-right">{{lensePriceLeft*leftQuantity}}</td>
          </tr>
          <tr *ngIf="rightOval && rightOval > 0">
            <td>Right: OVAL/DIA</td>
            <td class="text-right">{{displayRightQuantity}}</td>
            <td class="text-right">{{ rightOval }}</td>
            <td class="text-right">{{rightOval*rightQuantity}}</td>
          </tr>
          <tr *ngIf="leftOval && leftOval > 0">
            <td>Left: OVAL/DIA</td>
            <td class="text-right">{{displayLeftQuantity}}</td>
            <td class="text-right">{{ leftOval }}</td>
            <td class="text-right">{{leftOval*leftQuantity}}</td>
          </tr>

          <tr *ngIf="leftEtPrice && leftEtPrice > 0">
            <td>Left: ET</td>
            <td class="text-right">{{displayLeftQuantity}}</td>
            <td class="text-right">{{ leftEtPrice }}</td>
            <td class="text-right">{{leftEtPrice*leftQuantity}}</td>
          </tr>
          <tr *ngIf="rightEtPrice && rightEtPrice > 0">
            <td>Right: ET</td>
            <td class="text-right">{{displayRightQuantity}}</td>
            <td class="text-right">{{ rightEtPrice }}</td>
            <td class="text-right">{{rightEtPrice*rightQuantity}}</td>
          </tr>
          <tr *ngIf="leftCtPrice && leftCtPrice > 0">
            <td>Left: CT</td>
            <td class="text-right">{{displayLeftQuantity}}</td>
            <td class="text-right">{{ leftCtPrice }}</td>
            <td class="text-right">{{leftCtPrice*leftQuantity}}</td>
          </tr>
          <tr *ngIf="rightCtPrice && rightCtPrice > 0">
            <td>Right: CT</td>
            <td class="text-right">{{displayRightQuantity}}</td>
            <td class="text-right">{{ rightCtPrice }}</td>
            <td class="text-right">{{rightCtPrice*rightQuantity}}</td>
          </tr>
          <tr *ngIf="leftDecPrice && leftDecPrice > 0">
            <td>Left: DEC</td>
            <td class="text-right">{{displayLeftQuantity}}</td>
            <td class="text-right">{{ leftDecPrice }}</td>
            <td class="text-right">{{leftDecPrice*leftQuantity}}</td>
          </tr>
          <tr *ngIf="rightDecPrice && rightDecPrice > 0">
            <td>Right: DEC</td>
            <td class="text-right">{{displayRightQuantity}}</td>
            <td class="text-right">{{ rightDecPrice }}</td>
            <td class="text-right">{{rightDecPrice*rightQuantity}}</td>
          </tr>
          <tr *ngIf="leftPrismPrice && leftPrismPrice > 0">
            <td>Left: Prism</td>
            <td class="text-right">{{displayLeftQuantity}}</td>
            <td class="text-right">{{ leftPrismPrice }}</td>
            <td class="text-right">{{leftPrismPrice*leftQuantity}}</td>
          </tr>
          <tr *ngIf="rightPrismPrice && rightPrismPrice > 0">
            <td>Right: Prism</td>
            <td class="text-right">{{displayRightQuantity}}</td>
            <td class="text-right">{{ rightPrismPrice }}</td>
            <td class="text-right">{{rightPrismPrice*rightQuantity}}</td>
          </tr>
          <tr *ngIf="coatingPrice && coatingPrice > 0">
            <td>Coating</td>
            <td class="text-right">{{+displayRightQuantity+displayLeftQuantity}}</td>
            <td class="text-right">{{ coatingPrice }}</td>
            <td class="text-right">{{(+rightQuantity+leftQuantity)*coatingPrice}}</td>
          </tr>
          <tr *ngIf="hcPrice && hcPrice > 0">
            <td>HC</td>
            <td class="text-right">{{+displayRightQuantity+displayLeftQuantity}}</td>
            <td class="text-right">{{ hcPrice }}</td>
            <td class="text-right">{{(+rightQuantity+leftQuantity)*hcPrice}}</td>
          </tr>
          <tr
            *ngIf="selectedOtherServicePrice && selectedOtherServicePrice > 0"
          >
            <td>Other Price</td>
            <td class="text-right">{{+displayRightQuantity+displayLeftQuantity}}</td>
            <td class="text-right">{{ selectedOtherServicePrice }}</td>
            <td class="text-right">{{(+rightQuantity+leftQuantity)*selectedOtherServicePrice}}</td>
          </tr>
          <tr *ngIf="specialLogo && specialLogo > 0">
            <td>Special Logo</td>
            <td class="text-right">{{+displayRightQuantity+displayLeftQuantity}}</td>
            <td class="text-right">{{ specialLogo }}</td>
            <td class="text-right">{{(+rightQuantity+leftQuantity)*specialLogo}}</td>
          </tr>
          <tr *ngIf="engraveName && engraveName > 0">
            <td>Engrave Name</td>
            <td class="text-right">{{+displayRightQuantity+displayLeftQuantity}}</td>
            <td class="text-right">{{ engraveName }}</td>
            <td class="text-right">{{(+rightQuantity+leftQuantity)*engraveName}}</td>
          </tr>

          <tr *ngIf="mirrorPrice && mirrorPrice > 0">
            <td>Mirror</td>
            <td class="text-right">{{+displayRightQuantity+displayLeftQuantity}}</td>
            <td class="text-right">{{ mirrorPrice }}</td>
            <td class="text-right">{{(+rightQuantity+leftQuantity)*mirrorPrice}}</td>
          </tr>
          <tr *ngIf="tintPrice && tintPrice > 0">
            <td>Tint</td>
            <td class="text-right">{{+displayRightQuantity+displayLeftQuantity}}</td>
            <td class="text-right">{{ tintPrice }}</td>
            <td class="text-right">{{(+rightQuantity+leftQuantity)*tintPrice}}</td>
          </tr>
          <tr *ngIf="leftBaseCurvePrice && leftBaseCurvePrice > 0">
            <td>Left: Base Curve</td>
            <td class="text-right">{{displayLeftQuantity}}</td>
            <td class="text-right">{{ leftBaseCurvePrice }}</td>
            <td class="text-right">{{leftQuantity*leftBaseCurvePrice}}</td>
          </tr>
          <tr *ngIf="rightBaseCurvePrice && rightBaseCurvePrice > 0">
            <td>Right: Base Curve</td>
            <td class="text-right">{{displayRightQuantity}}</td>
            <td class="text-right">{{ rightBaseCurvePrice }}</td>
            <td class="text-right">{{rightQuantity*rightBaseCurvePrice}}</td>
          </tr>
          <tr *ngIf="fittingPrice && fittingPrice > 0">
            <td>Fitting</td>
            <td class="text-right">{{+displayRightQuantity+displayLeftQuantity}}</td>
            <td class="text-right">{{ fittingPrice }}</td>
            <td class="text-right">{{(+rightQuantity+leftQuantity)*fittingPrice}}</td>
          </tr>
          <tr *ngIf="tailorMadePrice && tailorMadePrice > 0">
            <td>Tailor Made</td>
            <td class="text-right">{{+displayRightQuantity+displayLeftQuantity}}</td>
            <td class="text-right">{{ tailorMadePrice }}</td>
            <td class="text-right">{{(+rightQuantity+leftQuantity)*tailorMadePrice}}</td>
            <!-- <td><input type="number" class="text-right" (input)="valueChanged()" [(ngModel)]="tailorMadePrice" NumberValidate></td> -->
          </tr>
          <tr>
            <td>Additional</td>
            <td class="text-right"></td>
            <td>
              <input
                type="number"
                class="text-right w-100"
                (input)="valueChanged()"
                [(ngModel)]="additional"
                NumberValidate
              />
            </td>
            <td class="text-right">{{additional}}</td>
          </tr>
          <tr>
            <td>Discont</td>
            <td class="text-right"></td>
            <td>
              <input
                type="number"
                class="text-right w-100"
                (input)="valueChanged()"
                [(ngModel)]="discount"
                NumberValidate
              />
            </td>
            <td class="text-right">{{discount}}</td>
          </tr>
        </tbody>
        <!-- SignNotAllowed="43" -->
        <tfoot>
          <tr>
            <td>Total</td>
            <td></td>
            <td></td>
            <td class="text-right">
              {{
                  (lensePriceRight*rightQuantity) +
                  (lensePriceLeft*leftQuantity) +
                  (rightOval*rightQuantity) +
                  (leftOval*leftQuantity) +
                  (leftEtPrice*leftQuantity) +
                  (rightEtPrice*rightQuantity) +
                  (leftCtPrice*leftQuantity) +
                  (rightCtPrice*rightQuantity) +
                  (leftDecPrice*leftQuantity) +
                  (rightDecPrice*rightQuantity) +
                  (leftPrismPrice*leftQuantity) +
                  (rightPrismPrice*rightQuantity) +
                  (leftQuantity*leftBaseCurvePrice) +
                  (rightQuantity*rightBaseCurvePrice) +
                  ((+rightQuantity+leftQuantity)*coatingPrice) +
                  ((+rightQuantity+leftQuantity)*hcPrice) +
                  ((+rightQuantity+leftQuantity)*selectedOtherServicePrice) +
                  ((+rightQuantity+leftQuantity)*specialLogo) +
                  ((+rightQuantity+leftQuantity)*engraveName) +
                  ((+rightQuantity+leftQuantity)*mirrorPrice) +
                  ((+rightQuantity+leftQuantity)*tintPrice) +
                  ((+rightQuantity+leftQuantity)*fittingPrice) +
                  ((+rightQuantity+leftQuantity)*tailorMadePrice) +
                  additional -
                  discount
              }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
