<div class="add-new-rows-page-wrapper">
        <h1 mat-dialog-title>Add New Rows</h1>
        <mat-dialog-content>
          <form class="example-form" [formGroup]="addNewRowsForm" (ngSubmit)="!addNewRowsForm.valid || addNewRows(addNewRowsForm)">
            <mat-form-field class="example-full-width">
              <input type="number" autocomplete="off" matInput placeholder="{{data?.label === 'CYL' ? 'SPH of rows': 'CX of rows'}}" formControlName="rows" required>
              <mat-error *ngIf="addNewRowsForm.get('rows').errors?.required">
                Value is required
              </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" *ngIf="data?.label === 'CYL'">
                <input type="number" matInput placeholder="Cyl of rows" formControlName="cylmax" required autocomplete="off">
                <mat-error *ngIf="addNewRowsForm.get('cylmax').errors?.required">
                  Cyl Value is required
                </mat-error>
              </mat-form-field>
            <button mat-raised-button color="primary" type="submit" class="button btn-block">Add</button>
          </form>
        </mat-dialog-content>
      </div>