import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { SharedService } from "../shared.service";
import { FormGroup, FormControl } from "@angular/forms";
@Component({
  selector: "multi-filters",
  templateUrl: "./multi-filters.component.html",
  styleUrls: ["./multi-filters.component.scss"]
})
export class MultiFiltersComponent implements OnInit, OnChanges {
  brandList: any[];
  subBrandList: any[];
  focalList: any[];
  indexList: any[];
  filterList: any[];
  filterBrandList: any[];
  coatingBrandList: any[];
  itemSizeList: any[];
  colorList: any[];
  customerList: any[];
  orderTypeList: any[];
  orderStatusList: any[];
  filterForm: FormGroup;
  @Input() filterOptions: any;
  @Input() columsType: any;
  @Input() filterDataSource: any;
  @Output() optSelected: EventEmitter<any> = new EventEmitter();
  tempFilter: any[];
  tempResult: any[];
  removable: boolean;
  selectable: boolean;
  temp: any = [];
  filterbySubmitToggle: boolean;
  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    // this.filterbySubmitToggle = true;
    // RESET
    this.brandList = [];
    this.subBrandList = [];
    this.focalList = [];
    this.indexList = [];
    this.filterBrandList = [];
    this.coatingBrandList = [];
    this.filterList = [];
    this.itemSizeList = [];
    this.colorList = [];
    this.customerList = [];
    this.orderTypeList = [];
    this.orderStatusList =[];
    // DROPDOWNS
    this.getAllBrands();
    this.getAllFocals();
    this.getAllIndexs();
    this.getAllFilterBrands();
    this.getAllCoatingBrands();
    this.getAllFilters();
    this.getAllItemSizes();
    this.getAllColorMasters();
    this.getAllCustomers();
    this.getAllSalesOrderPendingStatuss();
    this.getAllSalesOrderTypes();
  }

   /**
     // tslint:disable-next-line: no-redundant-jsdoc
     * @description ngOnChanges
     * @desc Fires when change in input properties are detected
     * @return void
     */
    ngOnChanges(changes: SimpleChanges) {
      console.log(changes)
      this.getInitForm(changes.filterDataSource.currentValue);
      // if (Object.keys(changes.filterOptions.currentValue).length > 0) {
        
      //  // this.filterOptions = changes.filterOptions.currentValue;
      // }
  }

  /**
   * @name getInitForm
   * @description filter form 
   * @param filter datascouce 
   */
  getInitForm(filterData?: any) {
    this.filterForm = new FormGroup({
      brandMasterId: new FormControl(filterData ? filterData.brandMasterId : "", []),
      subBrandMasterId: new FormControl(filterData ? filterData.subBrandMasterId : "", []),
      focalId: new FormControl(filterData ? filterData.focalId : "", []),
      indexId: new FormControl(filterData ? filterData.indexId : "", []),
      itemSizeId: new FormControl(filterData ? filterData.itemSizeId : "", []),
      colorMasterId: new FormControl(filterData ? filterData.itemSizeId : "", []),
      filterBrandId: new FormControl(filterData ? filterData.filterBrandId : "", []),
      filterId: new FormControl(filterData ? filterData.filterId : "", []),
      coatingBrandId: new FormControl(filterData ? filterData.coatingBrandId : "", []),
      customerId: new FormControl(filterData ? filterData.customerId : "", []),
      pendingStatusId: new FormControl(filterData ? filterData.customerId : "", []),
      salesOrderTypeId: new FormControl(filterData ? filterData.customerId : "", [])
    });
  }
  /**
   * @name getAllMaterials
   * @returns Promise
   * @description fetch all item
   */
  getAllBrands() {
    this.sharedService
      .getAllBrands()
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.brandList = list;
          } else {
            if (response.data.payload.active) {
              this.brandList = response.data.payload;
            }
          }
        }
        // if (response.data.completed) {
        //   this.brandList = response.data.payload;
        // }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

   /**
   * @name getAllItemSizes
   * @returns Promise
   * @description fetch all ItemSize
   */
  getAllItemSizes() {
    this.sharedService
      .getAllItemSizes()
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.itemSizeList = list;
          } else {
            if (response.data.payload.active) {
              this.itemSizeList = response.data.payload;
            }
          }
        }
        // if (response.data.completed) {
        //   this.itemSizeList = response.data.payload;
        // }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
   /**
   * @name getAllColorMasters
   * @returns Promise
   * @description fetch all colorList
   */
  getAllColorMasters() {
    this.sharedService
      .getAllColorMasters()
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.colorList = list;
          } else {
            if (response.data.payload.active) {
              this.colorList = response.data.payload;
            }
          }
        }
        // if (response.data.completed) {
        //   this.colorList = response.data.payload;
        // }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
  
    /**
   * @name getAllCustomers
   * @returns Promise
   * @description fetch all customers
   */
  getAllCustomers() {
    this.sharedService
      .getAllCustomers()
      .then(response => {
        // if (response.data.completed) {
        //   this.customerList = response.data.payload;
        // }
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.customerList = list;
          } else {
            if (response.data.payload.active) {
              this.customerList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
    /**
   * @name getAllSalesOrderPendingStatuss
   * @returns Promise
   * @description fetch all SalesOrderPendingStatus
   */
  getAllSalesOrderPendingStatuss() {
    this.sharedService
      .getAllSalesOrderPendingStatuss()
      .then(response => {
        if (response.data.completed) {
          // this.orderStatusList = response.data.payload;
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.orderStatusList = list;
          } else {
            if (response.data.payload.active) {
              this.orderStatusList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

   /**
   * @name getAllSalesOrderTypes
   * @returns Promise
   * @description fetch all SalesOrderType
   */
  getAllSalesOrderTypes() {
    this.sharedService
      .getAllSalesOrderTypes()
      .then(response => {
        if (response.data.completed) {
          this.orderTypeList = response.data.payload;
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.orderTypeList = list;
          } else {
            if (response.data.payload.active) {
              this.orderTypeList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
  
 /**
   * @name getAllSubBrandsByBrandMasterId
   * @returns Promise
   * @description fetch all SubBrand
   */
  getAllSubBrandsByBrandMasterId(brandMasterId) {
    if (brandMasterId) {
      this.subBrandList = [];
      this.sharedService
        .getAllSubBrandsByBrandMasterId(brandMasterId)
        .then(response => {
          // if (response.data.completed) {
          //   this.subBrandList = response.data.payload;
          // }
          if (response.data.completed) {
            if (response.data.payload.length > 0) {
              const list = [];
              response.data.payload.forEach(element => {
                if (element.active) {
                  list.push(element);
                }
              });
              this.subBrandList = list;
            } else {
              if (response.data.payload.active) {
                this.subBrandList = response.data.payload;
              }
            }
          }
        })
        .catch(err => {
          console.error("Error:  ", err);
        });
    }
  }

  /**
   * @name getAllFocals
   * @returns Promise
   * @description fetch all Focal
   */
  getAllFocals() {
    this.sharedService
      .getAllFocals()
      .then(response => {
        // if (response.data.completed) {
        //   this.focalList = response.data.payload;
        // }
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.focalList = list;
          } else {
            if (response.data.payload.active) {
              this.focalList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

  /**
   * @name getAllIndexs
   * @returns Promise
   * @description fetch all Index
   */
  getAllIndexs() {
    this.sharedService
      .getAllIndexs()
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.indexList = list;
          } else {
            if (response.data.payload.active) {
              this.indexList = response.data.payload;
            }
          }
        }
        // if (response.data.completed) {
        //   this.indexList = response.data.payload;
        // }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

  /**
   * @name getAllFilterBrands
   * @returns Promise
   * @description fetch all FilterBrand
   */
  getAllFilterBrands() {
    this.sharedService
      .getAllFilterBrands()
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.filterBrandList = list;
          } else {
            if (response.data.payload.active) {
              this.filterBrandList = response.data.payload;
            }
          }
        }
        // if (response.data.completed) {
        //   this.filterBrandList = response.data.payload;
        // }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

   /**
   * @name getAllFilters
   * @returns Promise
   * @description fetch all Filter
   */
  getAllFilters() {
    this.sharedService
      .getAllFilters()
      .then(response => {
        // if (response.data.completed) {
        //   this.filterList = response.data.payload;
        // }
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.filterList = list;
          } else {
            if (response.data.payload.active) {
              this.filterList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
  /**
   * @name getAllCoatingBrands
   * @returns Promise
   * @description fetch all CoatingBrand
   */
  getAllCoatingBrands() {
    this.sharedService
      .getAllCoatingBrands()
      .then(response => {
        // if (response.data.completed) {
        //   this.coatingBrandList = response.data.payload;
        // }
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.coatingBrandList = list;
          } else {
            if (response.data.payload.active) {
              this.coatingBrandList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
/**
   * @name remove
   * @return void
   * @description remove chip object and filter updated rows
   */
  remove(item: any) {
    console.log('remove', item)
     const index = this.tempResult.indexOf(item);
     if (index >= 0) { 
     
      this.tempResult.splice(index, 1);
        if (item.itemName == 'Brand') {
          this.filterForm.get('brandMasterId').setValue('');
        }
        if (item.itemName == 'SubBrand') {
          this.filterForm.get('subBrandMasterId').setValue('');
        }
        if (item.itemName == 'Focal') {
          this.filterForm.get('focalId').setValue('');
        }
        if (item.itemName == 'Index') {
          this.filterForm.get('indexId').setValue('');
        }
        if (item.itemName == 'ItemSize') {
          this.filterForm.get('itemSizeId').setValue('');
        }
        if (item.itemName == 'FilterBrand') {
          this.filterForm.get('filterBrandId').setValue('');
        }
        if (item.itemName == 'FilterId') {
          this.filterForm.get('filterId').setValue('');
        }
        if (item.itemName == 'CoatingBrand') {
          this.filterForm.get('coatingBrandId').setValue('');
        }
        if (item.itemName == 'ColorMaster') {
          this.filterForm.get('colorMasterId').setValue('');
        }
        if (item.itemName == 'Customer') {
          this.filterForm.get('customerId').setValue('');
        }
        if (item.itemName == 'OrderType') {
          this.filterForm.get('salesOrderTypeId').setValue('');
        }
        if (item.itemName == 'PendingStatus') {
          this.filterForm.get('pendingStatusId').setValue('');
        }
        
        this.submitFilter(this.filterForm);
       // this.filterbySubmitToggle = true;
     }
   
   
    // if (index >= 0) {
    //   this.tempResult.splice(index, 1);
    //   if (this.tempResult.length === 0) {
    //     this.rows = this.temp;
    //   } else {
    //     this.tempFilter = [];
    //     this.temp.forEach(item => {
    //       this.tempResult.forEach(element => {
    //         if (
    //           (element.itemName === "Brand" &&
    //             element.id === item.BrandMasterId) ||
    //           (element.itemName === "SubBrand" &&
    //             element.id === item.subBrandMasterId) ||
    //           (element.itemName === "Focal" && element.id === item.focalId) ||
    //           (element.itemName === "Index" && element.id === item.indexId) ||
    //           (element.itemName === "FilterBrand" &&
    //             element.id === item.filterBrandId) ||
    //           (element.itemName === "CoatingBrand" &&
    //             element.id === item.coatingBrandId) ||
    //           (element.itemName === "ItemSize" &&
    //             element.id === item.itemSizeId) ||
    //           (element.itemName === "ColorMaster" &&
    //             element.id === item.colorMasterId)
    //         ) {
    //           this.tempFilter.push(item);
    //         }
    //       });
    //     });
    //     console.log("udpate", this.tempFilter);
    //     this.rows = this.tempFilter;
    //   }
    // }
  }
  /**
   * @name submitFilter
   * @returns filter controls value
   */
  submitFilter(form: FormGroup) {
   
    const filter = {};
    if (form.value.brandMasterId) {
      filter['Brand'] =  form.value.brandMasterId;
    }
    if (form.value.subBrandMasterId) {
      filter['SubBrand'] =  form.value.subBrandMasterId;
    }
    if (form.value.focalId) {
      filter['Focal'] =  form.value.focalId;
    }
    if (form.value.indexId) {
     filter['Index'] =  form.value.indexId;
    }
    if (form.value.filterBrandId) {
      filter['FilterBrand'] =  form.value.filterBrandId;
    }
    if (form.value.filterId) {
      filter['FilterId'] =  form.value.filterId;
    }
    if (form.value.coatingBrandId) {
     filter['CoatingBrand'] =  form.value.coatingBrandId;
    }
    if (form.value.itemSizeId) {
      filter['ItemSize'] =  form.value.itemSizeId;
     }
     if (form.value.colorMasterId) {
      filter['ColorMaster'] =  form.value.colorMasterId;
     }
     if (form.value.customerId) {
      filter['Customer'] =  form.value.customerId;
     }
     if (form.value.salesOrderTypeId) {
      filter['OrderType'] =  form.value.salesOrderTypeId;
     }
     if (form.value.pendingStatusId) {
      filter['PendingStatus'] =  form.value.pendingStatusId;
     }
    
     this.tempResult = [];
     Object.entries(filter).map(([k,v]) => {
        if (v !== null) {
         this.tempResult.push({
           itemName: k,
           id: v
         });
        }
     });
         this.selectable = true;
         this.removable = true;
    this.optSelected.emit(filter);
    // this.filterbySubmitToggle = !this.filterbySubmitToggle;
  }
}
