<app-panel [footer]="false" class="mb-3" [backArrow]="false" [toggling]="false">
  <div class="heading">
   {{heading}}
  </div>
  <div class="body">
    <div class="text-center">
      <webcam [height]="300" [width]="300" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
        *ngIf="showWebcam; else imageContainer" [allowCameraSwitch]="allowCameraSwitch"
        [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
        (initError)="handleInitError($event)"></webcam>
      <ng-template #imageContainer>
        <img style="margin-bottom: 1rem;" [src]="webcamImage._imageAsDataUrl" width="292" height="222" />
      </ng-template>
      <div fxLayout="row" class="camera-btn-container" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button type="button"class="theme-blue" (click)="triggerSnapshot()" mat-fab>
          <mat-icon>linked_camera</mat-icon>
        </button>
        <button type="button" class="theme-blue" (click)="showWebcam = true" mat-fab>
          <mat-icon>settings_backup_restore</mat-icon>
        </button>
      </div>
    </div>
    
    <!--AUDIO-->
    <audio  id="incoming-call" loop preload>
      <source
      src="../assets/media/take_picture.mp3"
      type="audio/mp3"
      />
    </audio>
    <!--/ AUDIO-->
  </div>
  <!-- <div class="footer text-center">
    <button mat-button class="bt-primary-btn" (click)="proceed()" [disabled]="false">
      Proceed
    </button>
  </div> -->
</app-panel>