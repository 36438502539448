import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
    selector: 'dialog-add-rows',
    styleUrls: ['./add-rows.component.scss'],
    templateUrl: './add-rows.component.html',
  })
  export class DialogAddRows implements OnInit {
    addNewRowsForm: FormGroup;
    constructor(
      public dialogRef: MatDialogRef<DialogAddRows>,
      @Inject(MAT_DIALOG_DATA) public data) {
      }
  
    // onNoClick(): void {
    //   this.dialogRef.close();
    // }
  ngOnInit(){

      this.addNewRowsForm = new FormGroup({
          rows: new FormControl('', [Validators.required])
      });
      if(this.data && this.data.label === 'CYL') {
        this.addNewRowsForm.addControl('cylmax', new FormControl('', [Validators.required]));
      }
  }

  addNewRows(form: FormGroup) {
    this.dialogRef.close(form.value);
  }
  }