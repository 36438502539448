<dx-load-panel [hideOnOutsideClick]="false" shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading">
</dx-load-panel>
<div class="page-wrapper">
    <form [formGroup]="mcForm">
      <app-panel [footer]="true" class="mb-3">
        <div class="heading">{{isEdit ? 'Edit ' + heading : 'Add ' + heading}}
        </div>
        <div class="body">
          <div class="row">
            <div class="col-md-3">
              <div class="dx-field">
                <div>Coating <span>*</span>:</div>
                <!-- <dx-select-box [inputAttr]="{ autocomplete: 'off' }"   [items]="coatingList" placeholder="Choose Coating" [showClearButton]="true"
                  displayExpr="coatingName" (onInitialized)="focusInEvent($event)" valueExpr="coatingId" [searchEnabled]="true"
                  formControlName="coatingId">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Coating is required">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-select-box> -->
                <dx-tag-box [items]="coatingList" placeholder="Choose Coating"
                    displayExpr="coatingName" valueExpr="coatingId"
                    formControlName="coatingId" [showSelectionControls]="true"
                    [hideSelectedItems]="true" [searchEnabled]="true">
                    <dx-validator>
                      <dxi-validation-rule type="required" message="Coating is required">
                      </dxi-validation-rule>
                    </dx-validator>
                </dx-tag-box>
              </div>
            </div>
            <div class="col-md-3">
              <div class="dx-field">
                <div>Machine <span>*</span>:</div>
                <dx-select-box [inputAttr]="{ autocomplete: 'off' }"   [items]="machineList" placeholder="Choose Machine" [showClearButton]="true"
                  displayExpr="mechineName" valueExpr="coatingMachineId" [searchEnabled]="true"
                  formControlName="coatingMachineId">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Machine is required">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-select-box>
              </div>
            </div>
            <div class="col-md-3">
              <div class="dx-field">
                <div>Number of Rows for Sectors <span>*</span>:</div>
                <dx-number-box [inputAttr]="{ autocomplete: 'off' }"  placeholder="Rows"  formControlName="noOfRows" [min]="1"
                [max]="35" [showSpinButtons]="true">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="rows is required">
                    </dxi-validation-rule>
                </dx-validator>
                </dx-number-box>
              </div>
            </div>
            <div class="col-md-3">
              <div class="dx-field">
                <div>Process <span>*</span>:</div>
                <dx-tag-box [items]="processList" placeholder="Choose Process"
                    displayExpr="coatingProcessName" valueExpr="coatingProcessId"
                    formControlName="coatingProcessId" [showSelectionControls]="true"
                    [hideSelectedItems]="true" [searchEnabled]="true">
                    <dx-validator>
                      <dxi-validation-rule type="required" message="Process is required">
                      </dxi-validation-rule>
                    </dx-validator>
                </dx-tag-box>
                <!-- <dx-select-box [inputAttr]="{ autocomplete: 'off' }"   [items]="processList" placeholder="Choose Process" [showClearButton]="true"
                  displayExpr="coatingProcessName" valueExpr="coatingProcessId" [searchEnabled]="true"
                  formControlName="coatingProcessId">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Process is required">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-select-box> -->
              </div>
            </div>
            <div class="col-md-3">
              <div class="dx-field">
                <div>Sector One <span>*</span>:</div>
                <dx-number-box [inputAttr]="{ autocomplete: 'off' }"  placeholder="Sector One"  formControlName="sectorOne" [min]="75"
                [max]="90" [showSpinButtons]="true">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Sector One is required">
                    </dxi-validation-rule>
                </dx-validator>
                </dx-number-box>
              </div>
            </div>
            <div class="col-md-3">
              <div class="dx-field">
                <div>Sector Two <span>*</span>:</div>
                <dx-number-box [inputAttr]="{ autocomplete: 'off' }"  placeholder="Sector Two"  formControlName="sectorTwo" [min]="70"
                [max]="90" [showSpinButtons]="true">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Sector Two is required">
                    </dxi-validation-rule>
                </dx-validator>
                </dx-number-box>
              </div>
            </div>
            <div class="col-md-3">
              <div class="dx-field">
                <div>Sector Three <span>*</span>:</div>
                <dx-number-box [inputAttr]="{ autocomplete: 'off' }"  placeholder="Sector Three"  formControlName="sectorThree" [min]="70"
                [max]="90" [showSpinButtons]="true">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Sector Three is required">
                    </dxi-validation-rule>
                </dx-validator>
                </dx-number-box>
              </div>
            </div>
            <div class="col-md-3">
              <div class="dx-field">
                <div>Sector Four <span>*</span>:</div>
                <dx-number-box [inputAttr]="{ autocomplete: 'off' }"  placeholder="Sector Four"  formControlName="sectorFour" [min]="65"
                [max]="90" [showSpinButtons]="true">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Sector Four is required">
                    </dxi-validation-rule>
                </dx-validator>
                </dx-number-box>
              </div>
            </div>
            <div class="col-md-3">
              <div class="dx-field">
                <div>Sector Five <span>*</span>:</div>
                <dx-number-box [inputAttr]="{ autocomplete: 'off' }"  placeholder="Sector Five"  formControlName="sectorFive" [min]="60"
                [max]="90" [showSpinButtons]="true">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Sector Five is required">
                    </dxi-validation-rule>
                </dx-validator>
                </dx-number-box>
              </div>
            </div>
            <!-- <div class="col-md-3">
              <div class="dx-field">
                <div>Lens ReF #:</div>
                <dx-number-box [inputAttr]="{ autocomplete: 'off' }"  placeholder="ReFerence Number"  (onKeyUp)="getInitialMpl($event)" formControlName="salesOrderStockIssueDetailId">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Lens ReFerence Number is required">
                    </dxi-validation-rule>
                </dx-validator>
                </dx-number-box>
              </div>
            </div> -->
          </div>
        </div>
        <div class="footer">
          <!-- <div class="float-left mt-1 ml-2" *ngIf="isEdit">
            Active <dx-check-box  formControlName="active"></dx-check-box>
        </div> -->
          <dx-button id="button-serch" [disabled]="isEdit"   text="Generate " type="default" class="float-right ml-3" [useSubmitBehavior]="true"
          (click)="!mcForm.valid || getInitialMpl(mcForm)">
          </dx-button>
         
          
        </div>
      </app-panel>
    </form>
</div>
<swal #saveSwal></swal>