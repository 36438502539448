import { environment } from 'src/environments/environment';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from "@angular/router"; 
import { Title } from '@angular/platform-browser';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
    @ViewChild("saveSwal")  saveSwal: SwalComponent;
    public pageTitle:string;
    public breadcrumbs: {
        name: string;
        url: string
    }[] = [];
    version: any = environment.version;
    public settings: Settings;
    constructor(public appSettings:AppSettings,
                public router: Router, 
                public activatedRoute: ActivatedRoute,                
                public title:Title, public http: HttpClient){
            this.settings = this.appSettings.settings; 
            this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.appVersionValidate();        
                this.breadcrumbs = []; 
                this.parseRoute(this.router.routerState.snapshot.root); 
                this.pageTitle = "";
                this.breadcrumbs.forEach(breadcrumb => {
                    this.pageTitle += ' > ' + breadcrumb.name;
                })       
                this.title.setTitle(this.settings.name + this.pageTitle);
            }
        })   
    }

    /**
     * Check if the current version is the latest version, if not open a toast
     * to notify the user to hard reload the application
     */
    appVersionValidate() {
        this.http.get(environment.baseUrl + "AppVersion/GetAppLatestVersion").subscribe((res: any) => {
            if(res && res.payload && res.payload.appVersionNo !== this.version) {
                let interval = setInterval(() => {
                    if(this.saveSwal) {
                        clearInterval(interval);
                        this.saveSwal.swalOptions = {
                            icon: "error",
                            titleText: "Your system version is out of date please hard reload your application 'CTRL + SHIFT + R'",
                            showConfirmButton: false,
                            showCancelButton: false,
                            allowOutsideClick: false
                          };
                          this.saveSwal.fire();
                    }
                }, 10);
            }
        });
    
    }

    private parseRoute(node: ActivatedRouteSnapshot) { 
        if (node.data['breadcrumb']) {
            if(node.url.length){
                let urlSegments: UrlSegment[] = [];
                node.pathFromRoot.forEach(routerState => {
                    urlSegments = urlSegments.concat(routerState.url);
                });
                let url = urlSegments.map(urlSegment => {
                    return urlSegment.path;
                }).join('/');
                this.breadcrumbs.push({
                    name: node.data['breadcrumb'],
                    url: '/' + url
                }) 
            }         
        }
        if (node.firstChild) {
            this.parseRoute(node.firstChild);
        }
    }

    public closeSubMenus(){
        let menu = document.querySelector(".sidenav-menu-outer");
        if(menu){
            for (let i = 0; i < menu.children[0].children.length; i++) {
                let child = menu.children[0].children[i];
                if(child){
                    if(child.children[0].classList.contains('expanded')){
                        child.children[0].classList.remove('expanded');
                        child.children[1].classList.remove('show');
                    }
                }
            }
        }
    }


}
