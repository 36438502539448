import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[NumberValidate]",
})

// tslint:disable-next-line:directive-class-suffix
export class NumberValidate {
  constructor(private el: ElementRef) {}
  @HostListener("keypress", ["$event"]) onkeypress(evt) {
    if (
      (evt.which != 8 && evt.which != 0 && evt.which <= 46) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  }
}
