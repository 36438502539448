import { Directive, HostListener } from '@angular/core';
import {Location} from '@angular/common';
@Directive({
  selector: '[appBackButton]'
})
export class BackButtonDirective {

  constructor(private _location: Location) { 

    
  }
@HostListener("click") onClick() { 
  this._location.back();
 } 

}
