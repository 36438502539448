import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";
declare var $: any;
@Component({
  selector: "app-order-amount",
  templateUrl: "./order-amount.component.html",
  styleUrls: ["./order-amount.component.scss"],
})
export class OrderAmountComponent implements OnInit, OnChanges {
  @Input() lensePriceRight: number = 0;
  @Input() lensePriceLeft: number = 0;
  @Input() coatingPrice: number = 0;
  @Input() tintPrice: number = 0;
  @Input() fittingPrice: number = 0;
  @Input() leftCtPrice: number = 0;
  @Input() leftEtPrice: number = 0;
  @Input() mirrorPrice: number = 0;
  @Input() leftBaseCurvePrice: number = 0;
  @Input() rightBaseCurvePrice: number = 0;
  @Input() rightEtPrice: number = 0;
  @Input() rightCtPrice: number = 0;
  @Input() rightPrismPrice: number = 0;
  @Input() leftDecPrice: number = 0;
  @Input() additionalPrice: number = 0;
  @Input() tailorMadePrice: number = 0;
  @Input() discountPrice: number = 0;
  @Input() selectedOtherServicePrice: number = 0;
  @Input() engraveName: number = 0;
  @Input() specialLogo: number = 0;
  @Input() hcPrice: number = 0;
  @Input() leftOval: number = 0;
  @Input() rightOval: number = 0;
  @Input() rightDecPrice: number = 0;
  @Input() leftPrismPrice: number = 0;
  @Input() selectedTemplate = "";
  @Input() qtyRight: any;
  @Input() qtyLeft: any;
  @Output() valueChange = new EventEmitter();
  discount: any;
  additional: any;
  rightTotal: number = 0;
  leftTotal: number = 0;
  serviceTotal: number = 0;
  constructor() {}

  ngOnInit() {
   
    $(document).ready(() => {
      $("#dealInner").removeClass("hide");
      $("#close").addClass("hide");
      const countRightNav = $(".right-nav .card-body").height() + 10;
      const countHeight = "-" + countRightNav + "px";
      $(".right-nav").css({ bottom: countHeight });
      $("#dealInner").on("click", () => {
        $(".right-nav").stop().animate({
          bottom: "4px",
          slow: "300",
        });
        $("#dealInner").addClass("hide");
        $("#close").removeClass("hide");
      });
      $("#close").on("click", () => {
        var r = $(".right-nav").height() - 50;
        $(".right-nav")
          .stop()
          .animate({
            bottom: "-" + r,
            slow: "300",
          });
        $("#dealInner").removeClass("hide");
        $("#close").addClass("hide");
      });
    });
  }

  valueChanged() {
    // You can give any function name
    this.valueChange.emit({
      discount: this.discount,
      additional: this.additional,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.discount = this.discountPrice ? this.discountPrice : 0;
    this.additional = this.additionalPrice ? this.additionalPrice : 0;
  }

  /**
   * @name rightArrayPush
   * @return Array
   */
  get rightArrayPush() {
    let array = [];
    this.rightTotal = 0;
    if (this.lensePriceRight > 0) {
      array.push(this.lensePriceRight);
      this.rightTotal = this.lensePriceRight + this.rightTotal;
    }
    if (this.rightOval > 0) {
      array.push(this.rightOval);
      this.rightTotal = this.rightOval + this.rightTotal;
    }
    if (this.rightEtPrice > 0) {
      array.push(this.rightEtPrice);
      this.rightTotal = this.rightEtPrice + this.rightTotal;
    }
    if (this.rightCtPrice > 0) {
      array.push(this.rightCtPrice);
      this.rightTotal = this.rightCtPrice + this.rightTotal;
    }
    if (this.rightDecPrice > 0) {
      array.push(this.rightDecPrice);
      this.rightTotal = this.rightDecPrice + this.rightTotal;
    }
    if (this.rightPrismPrice > 0) {
      array.push(this.rightPrismPrice);
      this.rightTotal = this.rightPrismPrice + this.rightTotal;
    }
    if (this.rightBaseCurvePrice > 0) {
      array.push(this.rightBaseCurvePrice);
      this.rightTotal = this.rightBaseCurvePrice + this.rightTotal;
    }
   
    return array;
  }
  /**
   * @name leftArrayPush
   * @return Array
   */
  get leftArrayPush() {
    let array = [];
    this.leftTotal = 0;
    if (this.lensePriceLeft > 0) {
      array.push(this.lensePriceLeft);
      this.leftTotal = this.lensePriceLeft + this.leftTotal;
    }
    if (this.leftOval > 0) {
      array.push(this.leftOval);
      this.leftTotal = this.leftOval + this.leftTotal;
    }
    if (this.leftEtPrice > 0) {
      array.push(this.leftEtPrice);
      this.leftTotal = this.leftEtPrice + this.leftTotal;
    }
    if (this.leftCtPrice > 0) {
      array.push(this.leftCtPrice);
      this.leftTotal = this.leftCtPrice + this.leftTotal;
    }
    if (this.leftDecPrice > 0) {
      array.push(this.leftDecPrice);
      this.leftTotal = this.leftDecPrice + this.leftTotal;
    }
    if (this.leftPrismPrice > 0) {
      array.push(this.leftPrismPrice);
      this.leftTotal = this.leftPrismPrice + this.leftTotal;
    }
    if (this.leftBaseCurvePrice > 0) {
      array.push(this.leftBaseCurvePrice);
      this.leftTotal = this.leftBaseCurvePrice + this.leftTotal;
    }
    return array;
  }
    /**
   * @name servicesPush
   * @return Array
   */
  get servicesPush() {
    let array = [];
    this.serviceTotal = 0;
    if (this.coatingPrice > 0) {
      array.push(this.coatingPrice);
      this.serviceTotal = this.coatingPrice + this.serviceTotal;
    }
    if (this.hcPrice > 0) {
      array.push(this.hcPrice);
      this.serviceTotal = this.hcPrice + this.serviceTotal;
    }
    if (this.selectedOtherServicePrice > 0) {
      array.push(this.selectedOtherServicePrice);
      this.serviceTotal = this.selectedOtherServicePrice + this.serviceTotal;
    }
    if (this.specialLogo > 0) {
      array.push(this.specialLogo);
      this.serviceTotal = this.specialLogo + this.serviceTotal;
    }
    if (this.engraveName > 0) {
      array.push(this.engraveName);
      this.serviceTotal = this.engraveName + this.serviceTotal;
    }
    if (this.mirrorPrice > 0) {
      array.push(this.mirrorPrice);
      this.serviceTotal = this.mirrorPrice + this.serviceTotal;
    }
    if (this.tintPrice > 0) {
      array.push(this.tintPrice);
      this.serviceTotal = this.tintPrice + this.serviceTotal;
    }
    if (this.fittingPrice > 0) {
      array.push(this.fittingPrice);
      this.serviceTotal = this.fittingPrice + this.serviceTotal;
    }
    if (this.tailorMadePrice > 0) {
      array.push(this.tailorMadePrice);
      this.serviceTotal = this.tailorMadePrice + this.serviceTotal;
    }
    return array;
  }
}
