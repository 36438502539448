import { Directive, HostListener, ElementRef, ChangeDetectorRef, Input} from '@angular/core';
@Directive({
  selector : '[ToFixedDirective]'
})

export class ToFixedDirective {
    @Input('ToFixedDirective') stepValue: any;
  constructor(private el:ElementRef,
  private changeDetectRef: ChangeDetectorRef
  ){}

  @HostListener('focusout') focusout(){
      if(this.el.nativeElement.value)
      {
        const val = this.el.nativeElement.value;
       // 25.4
        // 25.10 %  0.25
        // 1 false
        // 0 true
      // const val = this.el.nativeElement.value % this.stepValue;
       this.el.nativeElement.value = parseFloat(val).toFixed(this.stepValue);
         this.changeDetectRef.detectChanges();
         return true;
      } else {
        this.el.nativeElement.value = "";
        this.changeDetectRef.detectChanges();
        return false;
      }
  }
}
