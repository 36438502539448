import { Directive, HostListener, ElementRef, Input } from '@angular/core';
export enum KEY_CODE {
    UP_ARROW = 38,
    DOWN_ARROW = 40
  }
@Directive({
  selector: '[BlockKeyUpDown]'
})

export class BlockKeyUpDown{
// @Input('BlockKeyUpDown') sign: any;
  constructor(private el:ElementRef){ }
  @HostListener('window:keyup', ['$event']) keyEvent(event: any){

    if (event.keyCode == KEY_CODE.UP_ARROW || event.keyCode == KEY_CODE.DOWN_ARROW)
    {
        event.stopPropagation();
        return false;
    }
    // if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57)
    // {
    //     evt.preventDefault();
    // }
  }
}
