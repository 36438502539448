<div fxLayout="row wrap" *ngIf="router.url != '/'">
    <div fxFlex="100" class="flex-p">
        <mat-card fxLayout="row" class="breadcrumb">
            <a *ngIf="router.url != '/'" routerLink="/" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center" (click)="closeSubMenus()">
                <mat-icon>home</mat-icon>
                <span class="breadcrumb-title">Home</span>
            </a>
            <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center">                 
                <a [hidden]="i == (breadcrumbs.length - 1)" [routerLink]="[breadcrumb.url]">{{breadcrumb.name}}</a>   
                <span [hidden]="i != (breadcrumbs.length - 1)" class="breadcrumb-title active">{{breadcrumb.name}}</span>
            </div> 
            <p class="version-container">{{version}}</p>
        </mat-card>
    </div> 
</div>
<swal #saveSwal></swal>