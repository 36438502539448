<div class="right-nav">
  <div class="card">
    <div class="card-header">
      <div class="float-left">Order Amount</div>
      <div class="float-right">
        <div id="dealInner">
          <i class="fa fa-angle-down fa-3" aria-hidden="true"></i>
        </div>
        <div id="close">
          <i class="fa fa-angle-up fa-3" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="card-body">
      <table class="table table-bordered">
        <tbody>
          <tr *ngIf="lensePriceRight && lensePriceRight > 0">
            <td>Lens Right</td>
            <td class="text-right">
              {{ lensePriceRight }}
            </td>
          </tr>
          <tr *ngIf="lensePriceLeft && lensePriceLeft > 0">
            <td>Lens Left</td>
            <td class="text-right">
              {{ lensePriceLeft }}
            </td>
          </tr>
          <tr *ngIf="rightOval && rightOval > 0">
            <td>Right: OVAL/DIA</td>
            <td class="text-right">{{ rightOval }}</td>
          </tr>
          <tr *ngIf="leftOval && leftOval > 0">
            <td>Left: OVAL/DIA</td>
            <td class="text-right">{{ leftOval }}</td>
          </tr>

          <tr *ngIf="leftEtPrice && leftEtPrice > 0">
            <td>Left: ET</td>
            <td class="text-right">{{ leftEtPrice }}</td>
          </tr>
          <tr *ngIf="rightEtPrice && rightEtPrice > 0">
            <td>Right: ET</td>
            <td class="text-right">{{ rightEtPrice }}</td>
          </tr>
          <tr *ngIf="leftCtPrice && leftCtPrice > 0">
            <td>Left: CT</td>
            <td class="text-right">{{ leftCtPrice }}</td>
          </tr>
          <tr *ngIf="rightCtPrice && rightCtPrice > 0">
            <td>Right: CT</td>
            <td class="text-right">{{ rightCtPrice }}</td>
          </tr>
          <tr *ngIf="leftDecPrice && leftDecPrice > 0">
            <td>Left: DEC</td>
            <td class="text-right">{{ leftDecPrice }}</td>
          </tr>
          <tr *ngIf="rightDecPrice && rightDecPrice > 0">
            <td>Right: DEC</td>
            <td class="text-right">{{ rightDecPrice }}</td>
          </tr>
          <tr *ngIf="leftPrismPrice && leftPrismPrice > 0">
            <td>Left: Prism</td>
            <td class="text-right">{{ leftPrismPrice }}</td>
          </tr>
          <tr *ngIf="rightPrismPrice && rightPrismPrice > 0">
            <td>Right: Prism</td>
            <td class="text-right">{{ rightPrismPrice }}</td>
          </tr>
          <tr *ngIf="coatingPrice && coatingPrice > 0">
            <td>Coating {{ selectedTemplate == "both" ? "2x2" : "" }}</td>
            <td class="text-right">{{ coatingPrice }}</td>
          </tr>
          <tr *ngIf="hcPrice && hcPrice > 0">
            <td>HC {{ selectedTemplate == "both" ? "2x2" : "" }}</td>
            <td class="text-right">{{ hcPrice }}</td>
          </tr>
          <tr
            *ngIf="selectedOtherServicePrice && selectedOtherServicePrice > 0"
          >
            <td>Other Price {{ selectedTemplate == "both" ? "2x2" : "" }}</td>
            <td class="text-right">{{ selectedOtherServicePrice }}</td>
          </tr>
          <tr *ngIf="specialLogo && specialLogo > 0">
            <td>Special Logo {{ selectedTemplate == "both" ? "2x2" : "" }}</td>
            <td class="text-right">{{ specialLogo }}</td>
          </tr>
          <tr *ngIf="engraveName && engraveName > 0">
            <td>Engrave Name {{ selectedTemplate == "both" ? "2x2" : "" }}</td>
            <td class="text-right">{{ engraveName }}</td>
          </tr>

          <tr *ngIf="mirrorPrice && mirrorPrice > 0">
            <td>Mirror {{ selectedTemplate == "both" ? "2x2" : "" }}</td>
            <td class="text-right">{{ mirrorPrice }}</td>
          </tr>
          <tr *ngIf="tintPrice && tintPrice > 0">
            <td>Tint {{ selectedTemplate == "both" ? "2x2" : "" }}</td>
            <td class="text-right">{{ tintPrice }}</td>
          </tr>
          <tr *ngIf="leftBaseCurvePrice && leftBaseCurvePrice > 0">
            <td>Left: Base Curve</td>
            <td class="text-right">{{ leftBaseCurvePrice }}</td>
          </tr>
          <tr *ngIf="rightBaseCurvePrice && rightBaseCurvePrice > 0">
            <td>Right: Base Curve</td>
            <td class="text-right">{{ rightBaseCurvePrice }}</td>
          </tr>
          <tr *ngIf="fittingPrice && fittingPrice > 0">
            <td>Fitting {{ selectedTemplate == "both" ? "2x2" : "" }}</td>
            <td class="text-right">{{ fittingPrice }}</td>
          </tr>
          <tr *ngIf="tailorMadePrice && tailorMadePrice > 0">
            <td>Tailor Made {{ selectedTemplate == "both" ? "2x2" : "" }}</td>
            <td class="text-right">{{ tailorMadePrice }}</td>
            <!-- <td><input type="number" class="text-right" (input)="valueChanged()" [(ngModel)]="tailorMadePrice" NumberValidate></td> -->
          </tr>
          <tr>
            <td>Additional</td>
            <td>
              <input
                type="number"
                class="text-right"
                (input)="valueChanged()"
                [(ngModel)]="additional"
                NumberValidate
              />
            </td>
          </tr>
          <tr>
            <td>Discont</td>
            <td>
              <input
                type="number"
                class="text-right"
                (input)="valueChanged()"
                [(ngModel)]="discount"
                NumberValidate
              />
            </td>
          </tr>
        </tbody>
        <!-- SignNotAllowed="43" -->
        <tfoot>
          <tr>
            <td>Total</td>
            <td class="text-right">
              {{
                  lensePriceRight +
                  lensePriceLeft +
                  rightOval +
                  leftOval +
                  leftEtPrice +
                  rightEtPrice +
                  leftCtPrice +
                  rightCtPrice +
                  leftDecPrice +
                  rightDecPrice +
                  leftPrismPrice +
                  rightPrismPrice +
                  leftBaseCurvePrice +
                  rightBaseCurvePrice +
                  coatingPrice +
                  hcPrice +
                  selectedOtherServicePrice +
                  specialLogo +
                  engraveName +
                  mirrorPrice +
                  tintPrice +
                  fittingPrice +
                  tailorMadePrice +
                  additional -
                  discount
              }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
