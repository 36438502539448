<div [fxLayout]="(menuParentId == 0) ? 'row' : 'column'" fxLayoutAlign="start center"  >
    <div *ngFor="let menu of menuItems" class="horizontal-menu-item w-100">
        <a *ngIf="menu.routerLink && !menu.hasSubMenu " mat-button 
            fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
            [routerLink]="[menu.routerLink]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"
            [matTooltip]="menu.title" matTooltipPosition="above" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
            [id]="'horizontal-menu-item-'+menu.id">
            <!-- <mat-icon class="horizontal-menu-icon">{{menu.icon}}</mat-icon> -->
            <span class="horizontal-menu-title">{{menu.title}}</span>
        </a>
        <a *ngIf="menu.href && !menu.subMenu" mat-button 
            fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
            [attr.href]="menu.href || ''" [attr.target]="menu.target || ''"
            [matTooltip]="menu.title" matTooltipPosition="above" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
            [id]="'horizontal-menu-item-'+menu.id">
            <!-- <mat-icon class="horizontal-menu-icon">{{menu.icon}}</mat-icon> -->
            <span class="horizontal-menu-title">{{menu.title}}</span> 
        </a>
        <a *ngIf="menu.hasSubMenu" mat-button 
            fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
            [matTooltip]="menu.title" matTooltipPosition="above" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
            [id]="'horizontal-menu-item-'+menu.id" class="sabMenu">
            <!-- <mat-icon class="horizontal-menu-icon">{{menu.icon}}</mat-icon> -->
            <span class="horizontal-menu-title float-left">{{menu.title}} </span> <span class="float-right">{{menuParentId == 0 ? '' : '>'}}</span>
        </a>

        <div *ngIf="menu.hasSubMenu" class="horizontal-sub-menu" [id]="'horizontal-sub-menu-'+menu.id">
            <!-- <div [ngStyle]="{'max-height': menu.hasSubMenu ? '400px'  : 'auto', 'overflow':  menu.hasSubMenu ? 'auto' :  'inherit'}"> -->
            <app-horizontal-menu [menuParentId]="menu.id"></app-horizontal-menu>
        <!-- </div> -->
        </div>
    </div>
</div>