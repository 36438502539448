import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { SharedService } from "../shared.service";
@Component({
  selector: "app-mc-master",
  templateUrl: "./mc-master.component.html",
  styleUrls: ["./mc-master.component.scss"]
})
export class McMasterComponent implements OnInit, OnChanges {
  loading: boolean;
  mcForm: FormGroup;
  @Input() dataSource: any;
  @Output() optSelected: EventEmitter<any> = new EventEmitter();
  @Output() updatedForm: EventEmitter<any> = new EventEmitter();
  isEdit: boolean;
  heading: string;
  coatingList: any[];
  machineList: any[];
  processList: any;
  constructor(private sharedService: SharedService) {
    this.getInitForm();
  }

  ngOnInit() {
    this.coatingList = [];
    this.machineList = [];
    this.processList = [];

    this.getAllCoatings();
    this.getAllCoatingMechine();
    this.getAllCoatingProcesses();
  }
  /**
     // tslint:disable-next-line: no-redundant-jsdoc
     * @description ngOnChanges
     * @desc Fires when change in input properties are detected
     * @return void
     */
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.dataSource.currentValue &&
      Object.keys(changes.dataSource.currentValue).length > 0
    ) {
      this.heading = changes.dataSource.currentValue.heading;
      this.isEdit = changes.dataSource.currentValue.isEdit;
      if (this.isEdit) {
        console.log("ngOnChanges", changes.dataSource.currentValue);
        this.mcForm.get('coatingId').setValue(changes.dataSource.currentValue.coatingId);
        this.mcForm.get('coatingMachineId').setValue(changes.dataSource.currentValue.coatingMachineId);
        this.mcForm.get('noOfRows').setValue(changes.dataSource.currentValue.noOfRows);
        this.mcForm.get('coatingProcessId').setValue(changes.dataSource.currentValue.coatingProcessId);
        this.mcForm.get('sectorOne').setValue(changes.dataSource.currentValue.sectorOne);
        this.mcForm.get('sectorTwo').setValue(changes.dataSource.currentValue.sectorTwo);
        this.mcForm.get('sectorThree').setValue(changes.dataSource.currentValue.sectorThree);
        this.mcForm.get('sectorFour').setValue(changes.dataSource.currentValue.sectorFour);
        this.mcForm.get('sectorFive').setValue(changes.dataSource.currentValue.sectorFive);
        this.mcForm.get('active').setValue(changes.dataSource.currentValue.active);
     
        this.mcForm.get('coatingId').disable();
        this.mcForm.get('coatingMachineId').disable();
        this.mcForm.get('noOfRows').disable();
        this.mcForm.get('coatingProcessId').disable();
        this.mcForm.get('sectorOne').disable();
        this.mcForm.get('sectorTwo').disable();
        this.mcForm.get('sectorThree').disable();
        this.mcForm.get('sectorFour').disable();
        this.mcForm.get('sectorFive').disable();
      }
    }
  }
  /**
   * @name focusInEvent
   * @returns void
   */
  focusInEvent(e) {
    setTimeout(() => {
      e.component.focus();
    }, 100);
  }
  /**
   * @name getInitForm
   * @returns void
   * @description init mpm form
   */
  getInitForm() {
    this.mcForm = new FormGroup({
      coatingId: new FormControl(null, [Validators.required]),
      coatingMachineId: new FormControl(null, [Validators.required]),
      noOfRows: new FormControl(null, [Validators.required]),
      coatingProcessId: new FormControl(null, [Validators.required]),
      sectorOne: new FormControl(75, [Validators.required]),
      sectorTwo: new FormControl(70, [Validators.required]),
      sectorThree: new FormControl(70, [Validators.required]),
      sectorFour: new FormControl(65, [Validators.required]),
      sectorFive: new FormControl(60, [Validators.required]),
      active: new FormControl(true, [])
    });
  }

  /**
   * @name getAllCoatings
   * @returns Promise
   * @description fetch all Coating
   */
  getAllCoatings() {
    this.sharedService
      .getAllCoatings()
      .then(response => {
        if (response.data.completed) {
          this.coatingList = response.data.payload;
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
  /**
   * @name getAllCoatingMechine
   * @returns Promise
   * @description fetch all CaotingMechine
   */
  getAllCoatingMechine() {
    this.sharedService
      .getAllCoatingMechine()
      .then(response => {
        if (response.data.completed) {
          this.machineList = response.data.payload;
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
  /**
   * @name getAllCoatingProcesses
   * @returns Promise
   * @description fetch all CoatingProcess
   */
  getAllCoatingProcesses() {
    this.sharedService
      .getAllCoatingProcesses()
      .then(response => {
        if (response.data.completed) {
          this.processList = response.data.payload;
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

  /**
   * @name getInitialMpl
   * @returns Promise
   * @description fetch  Initial
   */
  getInitialMpl(form: FormGroup) {
    let payload = {};
    let salesOrderCoatingProcess = [];
    let salesOrderCoating = [];
    form.value.coatingId.forEach(element => {
      salesOrderCoating.push(element);
    });
    form.value.coatingProcessId.forEach(element => {
      salesOrderCoatingProcess.push({coatingProcessId: element});
    });
    payload['coatingMachineId'] = form.value.coatingMachineId;
    payload['coatingId'] = salesOrderCoating;
    payload['salesOrderCoatingProcess'] = salesOrderCoatingProcess;
    payload['noOfRows'] = form.value.noOfRows;
    payload['sectorOne'] = form.value.sectorOne;
    payload['sectorTwo'] = form.value.sectorTwo;
    payload['sectorThree'] = form.value.sectorThree;
    payload['sectorFour'] = form.value.sectorFour;
    payload['sectorFive'] = form.value.sectorFive;
    payload['active'] = form.value.active;
    this.optSelected.emit(payload);
    // if (
    //   event &&
    //   event.event.key == "Enter" &&
    //   this.mcForm.valid &&
    //   !this.isEdit
    // ) {
    //   if (this.mcForm.valid) {
    //     this.optSelected.emit(this.mcForm.value);
    //   }
    // }
  }
}
