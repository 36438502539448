<div class="sale-inquiry-filter">
  <form [formGroup]="filterForm">
    <div class="row">
      <div class="col-md-3" *ngIf="configOptions?.salesOrderId">
        <div class="dx-field">
          <div>Order Number:</div>
          <dx-number-box [inputAttr]="{ autocomplete: 'off' }"  placeholder="Order Number" formControlName="salesOrderId"> </dx-number-box>
        </div>
      </div>
      <div class="col-md-3" *ngIf="configOptions?.customerId">
        <div class="dx-field">
          <div>Customer Name:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="customersList" placeholder="Choose Customer Name"
              [showClearButton]="true" displayExpr="customerName"
              valueExpr="customerId" [searchEnabled]="true"
              [readOnly]="customersList?.length == 0"
              formControlName="customerId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-3" *ngIf="configOptions?.pendingStatusId">
        <div class="dx-field">
          <div>Order Status:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="orderStatusList" placeholder="Choose Order Status"
              [showClearButton]="true" displayExpr="processName"
              valueExpr="pendingStatusId" [searchEnabled]="true"
              [readOnly]="orderStatusList?.length == 0"
              formControlName="pendingStatusId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-3" *ngIf="configOptions?.salesOrderTypeId">
        <div class="dx-field">
          <div>Order Type:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="orderTypeList" placeholder="Choose Order Status"
              [showClearButton]="true" displayExpr="salesOrderTypeName"
              valueExpr="salesOrderTypeId" [searchEnabled]="true"
              [readOnly]="orderTypeList?.length == 0"
              formControlName="salesOrderTypeId"
              (onSelectionChanged)="getAllSubBrandsByBrandMasterId(filterForm.get('brandMasterId').value)"
              >
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-3" *ngIf="configOptions?.brandMasterId">
        <div class="dx-field">
          <div>Brand:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="brandList" placeholder="Choose Brand"
              [showClearButton]="true" displayExpr="brandName"
              valueExpr="brandMasterId" [searchEnabled]="true"
              [readOnly]="brandList?.length == 0"
              formControlName="brandMasterId"
              (onSelectionChanged)="getAllSubBrandsByBrandMasterId(filterForm.get('brandMasterId').value)"
              >
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-3" *ngIf="configOptions?.subBrandMasterId">
        <div class="dx-field">
          <div>Sub Brand:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="subBrandList" placeholder="Choose Sub Brand"
              [showClearButton]="true" displayExpr="subBrandName"
              valueExpr="subBrandMasterId" [searchEnabled]="true"
              [readOnly]="subBrandList?.length == 0"
              formControlName="subBrandMasterId"
              >
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-3" *ngIf="configOptions?.fromDate">
        <div class="dx-field">
          <div>Date From:</div>
          <dx-date-box type="date" formControlName="fromDate" style="width: 100%"></dx-date-box>
        </div>
      </div>
      <div class="col-md-3" *ngIf="configOptions?.toDate">
        <div class="dx-field">
          <div>To Date:</div>
          <dx-date-box type="date"  formControlName="toDate" style="width: 100%"></dx-date-box>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="filterForm.get('salesOrderId').value || filterForm.get('customerId').value || 
    filterForm.get('pendingStatusId').value || filterForm.get('salesOrderTypeId').value || filterForm.get('brandMasterId').value ||
     filterForm.get('subBrandMasterId').value || (filterForm.get('fromDate').value && filterForm.get('toDate').value)">
      <div class="col-sm-12">
        <dx-button id="button" text="Search" type="default" class="w-100-per float-right"
        (click)="submitFilter(filterForm)"
        > </dx-button>
      </div>
    </div>
  </form>
</div>