<dx-load-panel
  [hideOnOutsideClick]="false"
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loading">
</dx-load-panel>
<div class="order-tracking" [ngStyle]="{'bottom': showPanel ? '0px ' : '-82px'}">
  <div class="heading clearfix" (click)="togglePanel()">
    <div class="float-left">Order Tracking</div>
    <div class="float-right"><i class="fa" [ngClass]="{'fa-minus': showPanel, 'fa-plus': !showPanel}" aria-hidden="true"></i></div>
  </div>
  <div class="body">
    <ul class="list-group" [ngClass]="(items?.length) ? 'list-group-style' : ''" *ngIf="showPanel">
        <li class="list-group-item p-2" *ngFor="let item of items">
          <i class="fa" [ngClass]="item.status ? 'fa-check-circle-o' : 'fa-info-circle'"></i>
          <span class="ml-3">{{item.name}}</span>
        </li>
    </ul>
  </div>
  <div class="footer">
    <dx-number-box [inputAttr]="{ autocomplete: 'off' }" [(ngModel)]="orderNumber" placeholder="Order #" 
    (valueChange)="getOrderStatus($event, orderNumber)"  #inputElement>
    </dx-number-box>
  </div>
  <!-- <app-panel [backArrow]="false" [showPanel]="false">
    <div class="heading">Order Tracking</div>
    <div class="body">
      here
    </div>
    <div class="footer">
      footer
    </div>
  </app-panel> -->
</div>