import { Observable, Subject, of } from "rxjs";

import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { HeaderInterceptorService } from "src/app/interceptors/header-interceptor";

@Injectable()
export class SharedService {
  private subject = new Subject<any>();
  selectedInput: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  constructor(private customAxios: HeaderInterceptorService){}
  /**
   * @name verifyByModuleName
   * @param {string} moduleName
   * @desc Returns boolean if specified module is find. It returns TRUE if module is not found in local storage module
   * @return boolean
   */
  static verifyByModuleName(formUrl: string, accessType: boolean): boolean {
    const userAccess = JSON.parse(localStorage.getItem("userRights"));
    if (userAccess) {
      // accessType = accessType.toLowerCase();
      for (let a = 0; a < userAccess.length; a++) {
        if (userAccess[a].formUrl == formUrl) {
          // if (userAccess[a].formUrl == 'finance/invoice-recovery/index') {
          //   console.log(userAccess[a])
          // }
          if (accessType && userAccess[a].canView) {
            return true;
          } else {
            return false;
          }
        }
      }
      // return true;
    }
    // return true;
  }

  /**
   * @name generateRange
   * @service SharedService
   * @param  {} start
   * @param  {} stop
   * @param  {} step
   */
  generateRange(start, stop, step) {
    if (typeof stop == "undefined") {
      // one param defined
      stop = start;
      start = 0;
    }

    if (typeof step == "undefined") {
      step = 1;
    }

    if ((step > 0 && start > stop) || (step < 0 && start <= stop)) {
      return [];
    }

    var result = [];
    for (var i = start; step > 0 ? i <= stop : i > stop; i += step) {
      result.push(i);
    }
    // adding 2 decimal places for all values
    result = result.map((n) => {
      return n.toFixed(2);
    });
    return result;
  }
  getRangeValues(val, cyl, sph) {
    let rangeStockListPayload: any = [];
    if (!val) {
      // if value empty then remove it from collection
      for (let i = 0; i < rangeStockListPayload.length; i++) {
        if (
          rangeStockListPayload[i].cyl === cyl &&
          rangeStockListPayload[i].sph === sph
        ) {
          rangeStockListPayload.splice(i, 1);
          break;
        }
      }
      console.info("rangeStockListPayload ", rangeStockListPayload);
      return;
    }
    // check whether the value already exist then modify it
    if (rangeStockListPayload.some((o) => o.cyl === cyl && o.sph === sph)) {
      for (let i = 0; i < rangeStockListPayload.length; i++) {
        if (
          rangeStockListPayload[i].cyl === cyl &&
          rangeStockListPayload[i].sph === sph
        ) {
          rangeStockListPayload[i]["qty"] = eval(val);
          break;
        }
      }
    } else {
      // else not found so push it
      let rangePairObj = {
        cyl: cyl,
        sph: sph,
        qty: eval(val),
      };
      rangeStockListPayload.push(rangePairObj);
    }
    console.info("rangeStockListPayload ", rangeStockListPayload);
    return rangeStockListPayload;
  }

  /**
   * @name getAllCompanyBranch
   * @service SharedService
   * @return Promise
   */
  getAllCompanyBranch() {
    return this.customAxios.get("CompanyBranch/GetAllCompanyBranches");
  }

  /**
   * @name getAllCompanyBranch
   * @service SharedService
   * @return Promise
   */
  getFirstCompanyBranchStore() {
    return this.customAxios.get("Store/GetAllStores");
  }

  /**
   * @name getAllCompanyBranch
   * @service SharedService
   * @return Promise
   */
  getAllItemTypes() {
    return this.customAxios.get("ItemType/GetAllItemTypes");
  }

  /**
   * @name getAllCompanyBranch
   * @service SharedService
   * @return Promise
   */
  getAllItemGroups() {
    return this.customAxios.get("ItemGroup/GetAllItemGroups");
  }

  /**
   * @name getSecondCompanyBranchStore
   * @service SharedService
   * @return Promise
   */
  getSecondCompanyBranchStore() {
    return this.customAxios.get("Store/GetAllStores");
  }

  /**
   * @name getAdjustmentReason
   * @service SharedService
   * @return Promise
   */
  getAdjustmentReason() {
    return this.customAxios.get("AdjustmentReason/GetAllAdjustmentReasons");
  }

  sendData(dataSource: any) {
    this.subject.next(dataSource);
  }

  clearData() {
    this.subject.next(null);
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  /**
   * @getAllCxTypes
   * @description all cx type
   * @return cx type id
   */
  getAllCxTypes() {
    return this.customAxios.get("CxType/GetAllCxTypes");
  }
  /**
   * Get user roles in JSON string
   * @returns {Observable<any>}
   */
  public getUserRoles(): Observable<any> {
    const roles: any = localStorage.getItem("userRights");
    try {
      return of(JSON.parse(roles));
    } catch (e) {}
  }

  // FILTERS
  /**
   * @name getAllBrands
   * @returns Promise
   * @description fetch all Brand
   */
  getAllBrands() {
    return this.customAxios.get(`BrandMaster/GetAllBrandMasters`);
  }

  /**
   * @name getAllSubBrandsByBrandMasterId
   * @returns Promise
   * @description fetch all SubBrand
   */
  getAllSubBrandsByBrandMasterId(brandMasterId) {
    return this.customAxios.get(
      `SubBrand/GetAllSubBrandsByBrandMasterId?brandMasterId=${brandMasterId}`
    );
  }

  /**
   * @name getAllFocals
   * @returns Promise
   * @description fetch all Focal
   */
  getAllFocals() {
    return this.customAxios.get(`Focal/GetAllFocals`);
  }
  /**
   * @name getAllIndexs
   * @returns Promise
   * @description fetch all Index
   */
  getAllIndexs() {
    return this.customAxios.get(`Index/GetAllIndexs`);
  }

  /**
   * @name getAllFilterBrands
   * @returns Promise
   * @description fetch all FilterBrand
   */
  getAllFilterBrands() {
    return this.customAxios.get(`FilterBrand/GetAllFilterBrands`);
  }

  /**
   * @name getAllFilters
   * @returns Promise
   * @description fetch all Filter
   */
  getAllFilters() {
    return this.customAxios.get(`Filter/GetAllFilters`);
  }

  /**
   * @name getAllCoatingBrands
   * @returns Promise
   * @description fetch all CoatingBrand
   */
  getAllCoatingBrands() {
    return this.customAxios.get(`CoatingBrand/GetAllCoatingBrands`);
  }

  /**
   * @name getAllCustomers
   * @returns Promise
   * @description fetch all Customer
   */
  getAllCustomers() {
    return this.customAxios.get(`Customer/GetAllCustomers`);
  }

  /**
   * @name getAllSalesOrderPendingStatuss
   * @returns Promise
   * @description fetch all SalesOrderPendingStatus
   */
  getAllSalesOrderPendingStatuss() {
    return this.customAxios.get(`SalesOrderPendingStatus/GetAllSalesOrderPendingStatuss`);
  }

  /**
   * @name getAllSalesOrderTypes
   * @returns Promise
   * @description fetch all SalesOrderType
   */
  getAllSalesOrderTypes() {
    return this.customAxios.get(`SalesOrderType/GetAllSalesOrderTypes`);
  }
  // END

  /**
   * @name getAllCoatings
   * @returns Promise
   * @description fetch all Coating
   */
  getAllCoatings() {
    return this.customAxios.get(`Coating/GetAllCoatings`);
  }

  /**
   * @name getAllCoatingMechine
   * @returns Promise
   * @description fetch all CaotingMechine
   */
  getAllCoatingMechine() {
    return this.customAxios.get(`CoatingMachine/GetAllCoatingMachines`);
  }
  /**
   * @name getAllCoatingProcesses
   * @returns Promise
   * @description fetch all CoatingProcess
   */
  getAllCoatingProcesses() {
    return this.customAxios.get(`CoatingProcess/GetAllCoatingProcesses`);
  }

  /**
   * @name getAllItemSizes
   * @returns Promise
   * @description fetch all ItemSize
   */
  getAllItemSizes() {
    return this.customAxios.get(`ItemSize/GetAllItemSizes`);
  }
  /**
   * @name getAllColorMasters
   * @returns Promise
   * @description fetch all ColorMaster
   */
  getAllColorMasters() {
    return this.customAxios.get(`ColorMaster/GetAllColorMasters`);
  }

  /**
   * @name getTotal
   * @param {Array} list
   * @param {key} string
   * @returns total
   */
  getTotal(list: any, valueKey: string) {
    let tot = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i][valueKey]) {
        tot += parseInt(list[i][valueKey]);
      }
    }
    return tot;
  }

  /**
   * @name debounce
   * @description custom debounce function
   * @returns fn
   */
  debounce(fn, delay) {
    let timeOutId;
    return (...args) => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
      timeOutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  }

  /**
   * @name getAllPricingTypes
   * @param {string} type
   * @returns Promise
   * @description fetch  PricingType
   */
  getAllPricingTypes(type: string) {
    return this.customAxios.get(`PricingType/GetAllPricingTypes?type=${type}`);
  }
  /**
   * @name getCityByCountryId
   * @param countryId
   * @returns Promise
   * @description fetch all Mpl
   */

  getCityByCountryId(countryId) {
    return this.customAxios.get(`City/GetCityByCountryId?countryId=${countryId}`);
  }

  /**
   * @name getAllCustomerCompanyBranchId
   * @param {number} companyBranchId
   * @returns Promise
   * @description fetch Customers
   */
  getAllCustomerCompanyBranchId(companyBranchId: number) {
    return this.customAxios.get(
      `Customer/GetAllCustomerCompanyBranchId?companyBranchId=${companyBranchId}`
    );
  }

  /**
   * @name getAllMaterials
   * @returns Promise
   * @description fetch all item
   */
  getAllMaterials() {
    return this.customAxios.get(`Material/GetAllMaterials`);
  }
  /**
   * @name getAllCompanyBranches
   * @returns Promise
   * @description fetch all CompanyBranch
   */

  getAllCompanyBranches() {
    return this.customAxios.get(`CompanyBranch/GetAllCompanyBranches`);
  }
  /**
   * @name GetAllUoms
   * @returns Promise
   * @description fetch all UOM
   */

  getAllUoms() {
    return this.customAxios.get(`UOM/GetAllUoms`);
  }

  /**
   * @name getCX
   * @param {number} range1
   * @param {number} range2
   * @returns
   * @memberof SharedService
   */
  getCX(range1: number, range2: number) {
    return this.customAxios.get(`ItemCatalog/GetCX?range1=${range1}&range2=${range2}`);
  }

  /**
   * @name getItemCatalogDetail
   * @param {number} itemcatalogId
   * @param {number} cxValue
   * @returns
   * @memberof SharedService
   */
  getItemCatalogDetail(itemcatalogId: number, cxValue: number) {
    return this.customAxios.get(
      `ItemCatalog/GetItemCatalogDetail?itemcatalogId=${itemcatalogId}&cx=${cxValue}`
    );
    //return this.customAxios.get(`ItemCatalog/GetItemCatalogDetailForCx?itemcatalogId=${itemcatalogId}&cx=${cxValue}`);
  }

  /**
   * @name getAllPricingTypes
   * @param {number} itemCatalogId
   * @param {number} sph
   * @param {number} cylOrAdd
   * @returns Promise
   * @description fetch  PricingType
   */
  getAvailableStock(payload: any) {
    return this.customAxios.get(
      "StockRequest/GetAvailableStock?ItemCatalogId=" +
        payload.itemCatalogId +
        "&Sph=" +
        payload.sph +
        "&CylOrAdd=" +
        payload.cylOrAdd
    );
  }

  /**
   * @name validateBarcodeRequestRange
   * @param {number} itemCatalogId
   * @param {number} itemTypeMasterId
   * @param {number} focalId
   * @param {number} sphmin
   * @param {number} sphmax
   * @param {number} cylmin
   * @param {number} cylmax
   * @param {*} payload
   * @returns
   * @memberof SharedService
   */
  validateBarcodeRequestRange(payload: any) {
    return this.customAxios.get(
      "VendorItemCatalog/ValidateBarcodeRequestRange?itemCatalogGroupId=" +
        payload.itemCatalogGroupId +
        "&ItemTypeMasterId=" +
        payload.itemTypeMasterId +
        "&FocalId=" +
        payload.focalId +
        "&Sphmin=" +
        payload.sphmin +
        "&Sphmax=" +
        payload.sphmax +
        "&Cylmin=" +
        payload.cylmin +
        "&Cylmax=" +
        payload.cylmax +
        "&Addmin=" +
        payload.addmin +
        "&Addmax=" +
        payload.addmax +
        "&Cxmin=" +
        payload.cxmin +
        "&Cxmax=" +
        payload.cxmax
    );
  }
  /**
   * @name getAllAvailableStock
   * @param {number} companyBranchId
   * @param {number} itemCatalogId
   * @param {number} isPositiveRange
   * @returns
   * @memberof SharedService
   */
  getAllAvailableStock(
    companyBranchId: number,
    itemCatalogGroupId: number,
    isPositiveRange: boolean
  ) {
    return this.customAxios.get(
      `StockRequest/GetAllAvailableStock?CompanyBranchId=${companyBranchId}&itemCatalogGroupId=${itemCatalogGroupId}&IsPositiveRange=${isPositiveRange}`
    );
  }

  /**
   * @name getCatalogByBarcode
   * @param {string} barCodeId
   * @returns
   * @memberof SharedService
   */
  getCatalogByBarcode(barCodeId: string) {
    return this.customAxios.get(
      `VendorItemCatalog/GetCatalogByBarcode?barCodeId=${barCodeId}`
    );
  }
  /**
   * @name getSPH
   * @param {number} range1
   * @param {number} range2
   * @returns sph range
   * @memberof salesService
   */
  getSPH(range1: number, range2: number) {
    return this.customAxios.get(`ItemCatalog/GetSPH?range1=${range1}&range2=${range2}`);
  }

  /**
   * @name getCYL
   * @param {number} range1
   * @param {number} range2
   * @returns cyl range
   * @memberof salesService
   */
  getCYL(range1: number, range2: number) {
    return this.customAxios.get(`ItemCatalog/GetCYL?range1=${range1}&range2=${range2}`);
  }

  /**
   * @name getADDF
   * @param {number} range1
   * @param {number} range2
   * @returns cyl range
   * @memberof salesService
   */
  getADDF(range1: number, range2: number) {
    return this.customAxios.get(`ItemCatalog/GetADDF?range1=${range1}&range2=${range2}`);
  }
  /**
   * @name getRangePrice
   * @param {any} payload
   * @returns Estimate Price
   * @memberof salesService
   */
  getRangePrice(payload: any) {
    return this.customAxios.post("Mpl/GetRangePrice", payload);
  }
  /**
   * @name addCSPPowerRange
   * @param {any} payload
   * @returns power ranges
   * @memberof salesService
   */
  addCSPPowerRange(payload: any) {
    return this.customAxios.post("CSPPowerRange/AddCSPPowerRange", payload);
  }

  /**
   * @name getAllCSPPowerRange
   * @returns
   * @memberof SharedService
   */
  getAllCSPPowerRange() {
    return this.customAxios.get(`CSPPowerRange/GetAllCSPPowerRange`);
  }

  /**
   * @name getCSPRange
   * @param {number} customerId
   * @param {number} itemCatalogId
   * @returns validate ranges
   * @memberof salesService
   */
  getCSPRange(customerId: number, itemCatalogId: number) {
    return this.customAxios.get(
      `CSPPowerRange/GetCSPRange?customerId=${customerId}&itemCatalogId=${itemCatalogId}`
    );
  }

    /**
   * @name getCSPRangeForPriceGroupMasterId
   * @param {number} customerId
   * @param {number} priceGroupMasterId
   * @returns validate ranges
   * @memberof salesService
   */
     getCSPRangeForPriceGroupMasterId(customerId: number, priceGroupMasterId: number) {
      return this.customAxios.get(
        `CSPPowerRange/GetCSPRangeForPriceGroupMasterId?customerId=${customerId}&priceGroupMasterId=${priceGroupMasterId}`
      );
    }

  /**
   * @name validateRangeByPower
   * @param {any} payload
   * @returns validate ranges by power
   * @memberof salesService
   */
  validateRangeByPower(payload: any) {
    return this.customAxios.get(
      `ItemCatalogRange/ValidateRangeByPower?ItemCatalogId=${
        payload.itemCatalogId
      }&FocalId=${payload.focalId}&Sphmin=${payload.sphmin}&Sphmax=${
        payload.sphmax
      }&Cylmin=${payload.cylmin ? payload.cylmin : 0}&Cylmax=${
        payload.cylmax ? payload.cylmax : 0
      }&Addmin=${payload.addmin ? payload.addmin : 0}&Addmax=${
        payload.addmax ? payload.addmax : 0
      }`
    );
  }
  /**
   * @name getItemTypeByMaterialId
   * @param {event}  materialId
   * @param {number} countryId
   * @return itemTypesList
   * @description fetch item type list by material Id
   *
   */
  getItemTypeByMaterialId(countryId, materialId) {
    return this.customAxios.get(
      "ItemType/GetAllItemTypeByMaterialId?materialId=" +
        materialId +
        "&countryId=" +
        countryId
    );
  }
  /**
   * @name getFocalByItemTypeMasterId
   * @param {event}  itemTypeMasterId
   * @return focalIdsList
   * @description fetch data by id
   *
   */
  getFocalByItemTypeMasterId(itemTypeMasterId) {
    return this.customAxios.get(
      "Focal/GetFocalsByItemTypeMasterId?itemTypeMasterId=" + itemTypeMasterId
    );
  }

  /**
   * @name getBrandByItemTypeMasterId
   * @param {event}  itemTypeMasterId
   * @return brandsList
   * @description fetch data by id
   *
   */
  getBrandsForItemCatalog(
    itemTypeMasterId: number,
    focalId: number,
    materialId: number
  ) {
    return this.customAxios.get(
      "Brand/GetBrandsForItemCatalog?itemTypeMasterId=" +
        itemTypeMasterId +
        "&focalId=" +
        focalId +
        "&materialId=" +
        materialId
    );
  }
  /**
   * @name getSubBrandByItemTypeMasterIdANDbrandMasterId
   * @param {event}  itemTypeMasterId
   * @param {number} brandMasterId
   * @return focalIdsList
   * @description fetch data by id
   *
   */
  getSubBrandByItemTypeMasterIdANDbrandMasterId(
    itemTypeMasterId,
    brandMasterId
  ) {
    return this.customAxios.get(
      "SubBrand/getSubBrandByItemTypeMasterIdANDbrandMasterId?itemTypeMasterId=" +
        itemTypeMasterId +
        "&brandMasterId=" +
        brandMasterId
    );
  }

  /**
   * @name getAllSubBrandMasterByBrandMasterId
   * @param {number} brandMasterId
   * @return focalIdsList
   * @description fetch data by id
   *
   */
  getAllSubBrandMasterByBrandMasterId(brandMasterId) {
    return this.customAxios.get(
      "SubBrandMaster/GetAllSubBrandMasterByBrandMasterId?brandMasterId=" +
        brandMasterId
    );
  }

  /**
   * @name getAllIndexByMaterialId
   * @returns Promise
   * @description fetch all Index by material id
   */
  getAllIndexByMaterialId(materialId: number) {
    return this.customAxios.get(`Index/GetIndexesByMaterialId?materialId=${materialId}`);
  }
  /**
   * @name getVendorByBrandMasterID
   * @param {event}  brandMasterID
   * @return brandsList
   * @description fetch data by id
   *
   */
  getVendorByBrandMasterID(brandMasterID: number) {
    return this.customAxios.get(
      `Vendor/GetAllVendorByBrandMasterId?brandMasterId=${brandMasterID}`
    );
  }
  /**
   * @name addItemCatalogRangeAssociation
   * @service InventoryService
   * @param payload
   * @method POST
   * @return Promise
   */
  filterAllItemCatalogs(payload: any) {
    return this.customAxios.get(
      `ItemCatalog/FilterAllItemCatalogs?MaterialId=${
        payload.materialId
      }&FocalId=${payload.focalId}&BrandMasterId=${
        payload.brandMasterId
      }&SubBrandMasterId=${payload.subBrandMasterId}&ItemTypeMasterId=${
        payload.itemTypeMasterId
      }&VendorId=${payload.vendorId ? payload.vendorId : 0}`
    );
  }

  /**
   * @name appFilterAllItemCatalogs
   * @service InventoryService
   * @param payload
   * @method POST
   * @return Promise
   */
  appFilterAllItemCatalogs(payload: any, priceGroup: boolean) {
    if (priceGroup) {
      return this.customAxios.get(
        `PriceGroup/GetPriceGroup?materialId=${payload.materialId}&FocalId=${
          payload.focalId
        }&BrandMasterId=${payload.brandMasterId}&IndexId=${
          payload.indexId ? payload.indexId : 0
        }&SubBrandMasterId=${payload.subBrandMasterId}&ItemTypeMasterId=${
          payload.itemTypeMasterId
        }&FilterId=${payload.filterId}&FilterBrandId=${payload.filterBrandId}`
      );
    } else {
      return this.customAxios.get(
        `ItemCatalog/FilterAllItemCatalogs?MaterialId=${
          payload.materialId
        }&FocalId=${payload.focalId}&BrandMasterId=${
          payload.brandMasterId
        }&SubBrandMasterId=${payload.subBrandMasterId}&ItemTypeMasterId=${
          payload.itemTypeMasterId
        }&VendorId=${payload.vendorId ? payload.vendorId : 0}&FilterId=${
          payload.filterId ? payload.filterId : 0
        }&FilterBrandId=${payload.filterBrandId ? payload.filterBrandId : 0}`
      );
    }
  }
  /**
   * @name getAllPowerRangeByItem
   * @service InventoryService
   * @param itemCatalogId:number
   * @param focalId:number
   * @param itemTypeMasterId:number
   * @method POST
   * @return Promise
   */
  getAllPowerRangeByItem(
    itemCatalogId: number,
    focalId: number,
    itemTypeMasterId: number
  ) {
    return this.customAxios.get(
      `ItemCatalog/FilterAllItemCatalogs?itemCatalogId=${itemCatalogId}&focalId=${focalId}&itemTypeMasterId=${itemTypeMasterId}`
    );
  }

  /**
   *@name getItemPricingByItemCatalogId
   * @param {number} itemCatalogId
   * @param {number} itemTypeMasterId
   * @param {number} brandMasterId
   * @returns
   * @memberof SalesService
   */
  getItemPricingByItemCatalogId(
    itemCatalogId: number,
    itemTypeMasterId: number,
    brandMasterId: number
  ) {
    return this.customAxios.get(
      `Pricing/GetItemPricingByItemCatalogId?itemCatalogId=${itemCatalogId}&itemTypeMasterId=${itemTypeMasterId}&brandMasterId=${brandMasterId}`
    );
  }
  /**
   *@name getPowerRanges
   * @param {number} brandMasterId
   * @param {number} materialId
   * @param {number} subBrandMasterId
   * @param {number} focalId
   * @param {number} itemTypeMasterId
   * @param {number} indexId
   * @param {number} itemCatalogId
   * @returns
   * @memberof SalesService
   */
  getPowerRanges(filters: any) {
    return this.customAxios.get(
      `PriceGroup/GetPowerRanges?brandMasterId=${
        filters.brandMasterId ? filters.brandMasterId : 0
      }&materialId=${
        filters.materialId ? filters.materialId : 0
      }&subBrandMasterId=${
        filters.subBrandMasterId ? filters.subBrandMasterId : 0
      }&focalId=${filters.focalId ? filters.focalId : 0}&itemTypeMasterId=${
        filters.itemTypeMasterId ? filters.itemTypeMasterId : 0
      }&indexId=${filters.indexId ? filters.indexId : 0}&itemCatalogId=${
        filters.itemCatalogGroupId ? filters.itemCatalogGroupId : 0
      }`
    );
  }
  /**
   * @name getAllItemCategories
   * @return ServicePricing
   * @description fetch data
   *
   */
  getAllItemCategories() {
    return this.customAxios.get("ItemCategory/GetAllItemCategories");
  }
  /**
   * @name getIndexsForPricing
   * @param {number} brandMasterId
   * @param {number} subBrandMasterId
   * @returns index
   * @memberof salesService
   */
  getIndexsForPricing(brandMasterId: number, subBrandMasterId: number) {
    return this.customAxios.get(
      `Index/GetIndexsForPricing?brandMasterId=${brandMasterId}&subBrandMasterId=${subBrandMasterId}`
    );
  }

  /**
   * @name fetFilterBrandsByFilterId
   * @returns Promise
   * @param filterId
   * @description fetch all filter brands
   */
  getFilterBrandsByFilterId(filterId: any) {
    return this.customAxios.get(
      `FilterBrand/GetFilterBrandsByFilterId?filterId=${filterId}`
    );
  }

  /**
   * @name getFilterBrandForPricing
   * @param {number} filterId
   * @param {number} brandMasterId
   * @param {number} subBrandMasterId
   * @returns filter brand
   * @memberof salesService
   */
  getFilterBrandForPricing(
    filterId: number,
    brandMasterId: number,
    subBrandMasterId: number
  ) {
    return this.customAxios.get(
      `FilterBrand/GetFilterBrandForPricing?filterId=${filterId}&brandMasterId=${brandMasterId}&subBrandMasterId=${subBrandMasterId}`
    );
  }

  /**
   * @name getFilter
   * @param {number} brandMasterId
   * @param {number} subBrandMasterId
   * @returns filter list
   * @memberof salesService
   */
  getFilter(brandMasterId: number, subBrandMasterId: number) {
    return this.customAxios.get(
      `Filter/GetFilter?brandMasterId=${brandMasterId}&subBrandMasterId=${subBrandMasterId}`
    );
  }

  /**
   * @name getFilter
   * @param {number} salesOrderId
   * @param {number} companyBranchId
   * @returns filter list
   * @memberof salesService
  */
  getSalesOrderStatus(salesOrderId: number, companyBranchId: number) {
    return this.customAxios.get(
      `SalesOrder/GetSalesOrderStatus?salesOrderId=${salesOrderId}&companyBranchId=${companyBranchId}`
    );
  }
  /**
   * @name getAllItemCatalogGroup
   * @service InventoryService
   * @param payload
   * @method POST
   * @return Promise
  */
   getAllItemCatalogGroup(payload: any) {
    return this.customAxios.get(`ItemCatalog/GetAllItemCatalogGroup?MaterialId=${payload.materialId}&FocalId=${payload.focalId}&BrandMasterId=${payload.brandMasterId}&FilterId=${payload.filterId ? payload.filterId : 0}&IndexId=${payload.indexId ? payload.indexId : 0}`);
  }


   /**
   * @name getByItemCatalogId
   * @param {number} itemCatalogGroupId
   * @returns filter list
   * @memberof salesService
   */
    getByItemCatalogId(itemCatalogGroupId: number) {
      return this.customAxios.get(
        `ItemCatalogRangeAssociation/GetByItemCatalogId?id=${itemCatalogGroupId}`
      );
    }
}
