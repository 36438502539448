export class RingToneService {

  /**
   * @name constructor
   * @return void
   */
  constructor() {}

  /**
   * @name audio
   * @param {string} Type of audio expected ['play', 'pause']
   * @desc Plays audio from DON
   * @return void
   */
  audio(type: string, audioUrl?: string): void {
    if (!audioUrl) {
      audioUrl = 'assets/media/incoming_call.mp3';
    }
    const audioElement:any  = document.getElementById('incoming-call');
    audioElement.src = audioUrl;
    // PLAY AUDIO
    if (type === 'play') {
      audioElement.play();
    }

    // PAUSE AUDIO
    if (type === 'pause') {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
  }
}
