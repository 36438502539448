import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { SharedService } from "../shared.service";
import { FormGroup, FormControl } from "@angular/forms";
@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"]
})
export class FiltersComponent implements OnInit, OnChanges {
  brandList: any[];
  subBrandList: any[];
  focalList: any[];
  indexList: any[];
  filterList: any[];
  filterBrandList: any[];
  coatingBrandList: any[];
  
  filterForm: FormGroup;
  @Input() filterOptions: any;
  @Input() colums: any;
  @Input() filterDataSource: any;
  @Output() optSelected: EventEmitter<any> = new EventEmitter();
  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    // RESET
    this.brandList = [];
    this.subBrandList = [];
    this.focalList = [];
    this.indexList = [];
    this.filterBrandList = [];
    this.coatingBrandList = [];
    this.filterList = [];
    // DROPDOWNS
    this.getAllBrands();
    this.getAllFocals();
    this.getAllIndexs();
    this.getAllFilterBrands();
    this.getAllCoatingBrands();
    this.getAllFilters();
    
  }

   /**
     // tslint:disable-next-line: no-redundant-jsdoc
     * @description ngOnChanges
     * @desc Fires when change in input properties are detected
     * @return void
     */
    ngOnChanges(changes: SimpleChanges) {
      console.log(changes)
      this.getInitForm(changes.filterDataSource.currentValue);
      // if (Object.keys(changes.filterOptions.currentValue).length > 0) {
        
      //  // this.filterOptions = changes.filterOptions.currentValue;
      // }
  }

  /**
   * @name getInitForm
   * @description filter form 
   * @param filter datascouce 
   */
  getInitForm(filterData?: any) {
    this.filterForm = new FormGroup({
      brandMasterId: new FormControl(filterData ? filterData.brandMasterId : "", []),
      subBrandMasterId: new FormControl(filterData ? filterData.subBrandMasterId : "", []),
      focalId: new FormControl(filterData ? filterData.focalId : "", []),
      indexId: new FormControl(filterData ? filterData.indexId : "", []),
      filterBrandId: new FormControl(filterData ? filterData.filterBrandId : "", []),
      filterId: new FormControl(filterData ? filterData.filterId : "", []),
      coatingBrandId: new FormControl(filterData ? filterData.coatingBrandId : "", [])
    });
  }
  /**
   * @name getAllMaterials
   * @returns Promise
   * @description fetch all item
   */
  getAllBrands() {
    this.sharedService
      .getAllBrands()
      .then(response => {
        if (response.data.completed) {
          this.brandList = response.data.payload;
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
 /**
   * @name getAllSubBrandsByBrandMasterId
   * @returns Promise
   * @description fetch all SubBrand
   */
  getAllSubBrandsByBrandMasterId(brandMasterId) {
    if (brandMasterId) {
      this.subBrandList = [];
      this.sharedService
        .getAllSubBrandsByBrandMasterId(brandMasterId)
        .then(response => {
          if (response.data.completed) {
            this.subBrandList = response.data.payload;
          }
        })
        .catch(err => {
          console.error("Error:  ", err);
        });
    }
  }

  /**
   * @name getAllFocals
   * @returns Promise
   * @description fetch all Focal
   */
  getAllFocals() {
    this.sharedService
      .getAllFocals()
      .then(response => {
        if (response.data.completed) {
          this.focalList = response.data.payload;
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

  /**
   * @name getAllIndexs
   * @returns Promise
   * @description fetch all Index
   */
  getAllIndexs() {
    this.sharedService
      .getAllIndexs()
      .then(response => {
        if (response.data.completed) {
          this.indexList = response.data.payload;
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

  /**
   * @name getAllFilterBrands
   * @returns Promise
   * @description fetch all FilterBrand
   */
  getAllFilterBrands() {
    this.sharedService
      .getAllFilterBrands()
      .then(response => {
        if (response.data.completed) {
          this.filterBrandList = response.data.payload;
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

   /**
   * @name getAllFilters
   * @returns Promise
   * @description fetch all Filter
   */
  getAllFilters() {
    this.sharedService
      .getAllFilters()
      .then(response => {
        if (response.data.completed) {
          this.filterList = response.data.payload;
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
  /**
   * @name getAllCoatingBrands
   * @returns Promise
   * @description fetch all CoatingBrand
   */
  getAllCoatingBrands() {
    this.sharedService
      .getAllCoatingBrands()
      .then(response => {
        if (response.data.completed) {
          this.coatingBrandList = response.data.payload;
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

  /**
   * @name submitFilter
   * @returns filter controls value
   */
  submitFilter(form: FormGroup) {
    // let filter: any = [];
    const filter = {}
    if (form.value.brandMasterId) {
      filter['Brand'] =  form.value.brandMasterId;
      //filter.push({name: 'Brand', brandMasterId: form.value.brandMasterId});

    }
    if (form.value.subBrandMasterId) {
      //filter.push({name: 'Sub Brand', subBrandMasterId: form.value.subBrandMasterId});
      filter['SubBrand'] =  form.value.subBrandMasterId;
    }
    if (form.value.focalId) {
      //filter.push({name: 'Focal', focalId: form.value.focalId});
      filter['Focal'] =  form.value.focalId;
    }
    if (form.value.indexId) {
     // filter.push({name: 'index', indexId: form.value.indexId});
     filter['Index'] =  form.value.indexId;
    }
    if (form.value.filterBrandId) {
      //filter.push({name: 'Filter Brand', filterBrandId: form.value.filterBrandId});
      filter['FilterBrand'] =  form.value.filterBrandId;
    }
    if (form.value.filterId) {
      //filter.push({name: 'Filter Brand', filterBrandId: form.value.filterBrandId});
      filter['FilterId'] =  form.value.filterId;
    }
    
    if (form.value.coatingBrandId) {
     // filter.push({name: 'Coating Brand', coatingBrandId: form.value.coatingBrandId});
     filter['CoatingBrand'] =  form.value.coatingBrandId;
    }
    // const filter = {
    //   Brand: form.value.brandMasterId,
    //   SubBrand: form.value.subBrandMasterId,
    //   Focal: form.value.focalId,
    //   Index: form.value.indexId,
    //   FilterBrand: form.value.filterBrandId,
    //   CoatingBrand: form.value.coatingBrandId
    // }
    this.optSelected.emit(filter);
  }
}
