<div class="right-nav">
  <div class="card">
    <div class="card-header">
      <div class="float-left">Order Amount</div>
      <div class="float-right">
        <div id="dealInner">
          <i class="fa fa-angle-down fa-3" aria-hidden="true"></i>
        </div>
        <div id="close">
          <i class="fa fa-angle-up fa-3" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="card-body">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Side</th>
            <th>
              Item
            </th>
            <th class="text-center">
              Qty
            </th>
            <th class="text-center">
              Rate
            </th>
            <th class="text-right">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
           <!-- START RIGHT SIDE -->
         <ng-container *ngIf="selectedTemplate == 'both' || selectedTemplate == 'right'">
            <tr *ngIf="rightArrayPush.length > 0">
              <td [attr.rowspan]="rightArrayPush.length + 1" style="vertical-align: middle;">
                Right
              </td>
            </tr>
            <tr *ngIf="lensePriceRight && lensePriceRight > 0">
              <td>
                Lense
              </td>
              <td class="text-center">
               {{qtyRight}}
              </td>
              <td class="text-center">
                {{lensePriceRight / qtyRight}}
              </td>
              <td class="text-right">
                {{lensePriceRight}}
               </td>
            </tr>
            <tr *ngIf="rightOval && rightOval > 0">
              <td>
                OVAL/DIA
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
                {{rightOval / qtyRight}}
              </td>
              <td class="text-right">
                {{rightOval}}
               </td>
            </tr>
            <tr *ngIf="rightEtPrice && rightEtPrice > 0">
              <td>
                ET
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
               {{rightEtPrice / qtyRight}}
              </td>
              <td class="text-right">
                {{rightEtPrice}}
               </td>
            </tr>
            <tr *ngIf="rightCtPrice && rightCtPrice > 0">
              <td>
                CT
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
               {{rightCtPrice / qtyRight}}
              </td>
              <td class="text-right">
                {{rightCtPrice}}
               </td>
            </tr>
            <tr *ngIf="rightDecPrice && rightDecPrice > 0">
              <td>
                DEC
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
               {{rightDecPrice / qtyRight}}
              </td>
              <td class="text-right">
                {{rightDecPrice}}
               </td>
            </tr>
            <tr *ngIf="rightPrismPrice && rightPrismPrice > 0">
              <td>
                Prism
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
               {{rightPrismPrice / qtyRight}}
              </td>
              <td class="text-right">
                {{rightPrismPrice}}
               </td>
            </tr>
            <tr *ngIf="rightBaseCurvePrice && rightBaseCurvePrice > 0">
              <td>
                Base Curve
              </td>
              <td class="text-center">
                {{qtyRight}}
              </td>
              <td class="text-center">
               {{rightBaseCurvePrice / qtyRight}}
              </td>
              <td class="text-right">
                {{rightBaseCurvePrice}}
               </td>
            </tr>
            <!-- END RIGHT SIDE -->
         </ng-container>
         <!-- / END RIGHT SIDE -->
          <!-- START LEFT SIDE -->
         <ng-container *ngIf="selectedTemplate == 'both' || selectedTemplate == 'left'">
          <tr *ngIf="leftArrayPush.length > 0">
             <td [attr.rowspan]="leftArrayPush.length + 1" style="vertical-align: middle;">
               Left
             </td>
           </tr>
          <tr *ngIf="lensePriceLeft && lensePriceLeft > 0">
            <td>
              Lense
            </td>
            <td class="text-center">
              {{qtyLeft}}
            </td>
            <td class="text-center">
             {{lensePriceLeft / qtyLeft}}
            </td>
            <td class="text-right">
              {{lensePriceLeft}}
             </td>
          </tr>
          <tr *ngIf="leftOval && leftOval > 0">
            <td>
              OVAL/DIA
            </td>
            <td class="text-center"> 
              {{qtyLeft}}
            </td>
            <td class="text-center">
             {{leftOval / qtyLeft}}
            </td>
            <td class="text-right">
              {{leftOval}}
             </td>
          </tr>
          <tr *ngIf="leftEtPrice && leftEtPrice > 0">
            <td>
              ET
            </td>
            <td class="text-center">
              {{qtyLeft}}
            </td>
            <td class="text-center">
             {{leftEtPrice / qtyLeft}}
            </td>
            <td class="text-right">
              {{leftEtPrice}}
             </td>
          </tr>
          <tr *ngIf="leftCtPrice && leftCtPrice > 0">
            <td>
              CT
            </td>
            <td class="text-center">
              {{qtyLeft}}
            </td>
            <td class="text-center">
             {{leftCtPrice / qtyLeft}}
            </td>
            <td class="text-right">
              {{leftCtPrice}}
             </td>
          </tr>
          <tr *ngIf="leftDecPrice && leftDecPrice > 0">
            <td>
              DEC
            </td>
            <td class="text-center"> 
              {{qtyLeft}}
            </td>
            <td class="text-center">
             {{leftDecPrice / qtyLeft}}
            </td>
            <td class="text-right">
              {{leftDecPrice}}
             </td>
          </tr>
          <tr *ngIf="leftPrismPrice && leftPrismPrice > 0">
            <td>
              Prism
            </td>
            <td class="text-center">
              {{qtyLeft}}
            </td>
            <td class="text-center">
             {{leftPrismPrice / qtyLeft}}
            </td>
            <td class="text-right">
              {{leftPrismPrice}}
             </td>
          </tr>
          <tr *ngIf="leftBaseCurvePrice && leftBaseCurvePrice > 0">
            <td>
              Base Curve
            </td>
            <td class="text-center">
              {{qtyLeft}}
            </td>
            <td class="text-center">
             {{leftBaseCurvePrice / qtyLeft}}
            </td>
            <td class="text-right">
              {{leftBaseCurvePrice}}
             </td>
          </tr>
          <!-- / END LEFT SIDE -->
         </ng-container>
          <!-- / END LEFT SIDE -->
         <!-- SERVICES -->
         <ng-container *ngIf="servicesPush.length > 0">
          <tr>
            <td [attr.rowspan]="servicesPush.length + 1" style="vertical-align: middle;">
              Services
            </td>
          </tr>
            <!-- SERVICES -->
            <tr *ngIf="coatingPrice && coatingPrice > 0">
              <td>
                Coating {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{coatingPrice / (qtyRight * 2)}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{coatingPrice / qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{coatingPrice / qtyLeft}}
                </span>
              </td>
              <td class="text-right">
                {{coatingPrice}}
               </td>
            </tr>
            <tr *ngIf="hcPrice && hcPrice > 0">
              <td>
                HC {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{hcPrice / (qtyRight * 2)}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{hcPrice / qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{hcPrice / qtyLeft}}
                </span>
              </td>
              <td class="text-right">
                {{hcPrice}}
               </td>
            </tr>
            <tr *ngIf="selectedOtherServicePrice && selectedOtherServicePrice > 0">
              <td>
                Others {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{selectedOtherServicePrice / (qtyRight * 2)}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{selectedOtherServicePrice / qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{selectedOtherServicePrice / qtyLeft}}
                </span>
              </td>
              <td class="text-right">
                {{selectedOtherServicePrice}}
               </td>
            </tr>
            <tr *ngIf="specialLogo && specialLogo > 0">
              <td>
                Special Logo 
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{specialLogo / qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{specialLogo / qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{specialLogo / qtyLeft}}
                </span>
              </td>
              <td class="text-right">
                {{specialLogo}}
               </td>
            </tr>
            <tr *ngIf="engraveName && engraveName > 0">
              <td>
                Engrave Name 
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{engraveName / qtyRight }}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{engraveName / qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{engraveName / qtyLeft}}
                </span>
              </td>
              <td class="text-right">
                {{engraveName}}
               </td>
            </tr>
            <tr *ngIf="mirrorPrice && mirrorPrice > 0">
              <td>
                Mirror {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{mirrorPrice / (qtyRight * 2)}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{mirrorPrice / qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{mirrorPrice / qtyLeft}}
                </span>
              </td>
              <td class="text-right">
                {{mirrorPrice}}
               </td>
            </tr>
            <tr *ngIf="tintPrice && tintPrice > 0">
              <td>
                Tint {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{tintPrice / (qtyRight * 2)}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{tintPrice / qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{tintPrice / qtyLeft}}
                </span>
              </td>
              <td class="text-right">
                {{tintPrice}}
               </td>
            </tr>
            <tr *ngIf="fittingPrice && fittingPrice > 0">
              <td>
                Fitting {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{fittingPrice / (qtyRight * 2)}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{fittingPrice / qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{fittingPrice / qtyLeft}}
                </span>
              </td>
              <td class="text-right">
                {{fittingPrice}}
               </td>
            </tr>
            <tr *ngIf="tailorMadePrice && tailorMadePrice > 0">
              <td>
                Tailor Made {{selectedTemplate == 'both' ? 'x2' : ''}}
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{qtyRight * 2}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{qtyLeft}}
                </span>
              </td>
              <td class="text-center">
                <span *ngIf="selectedTemplate == 'both'">
                  {{tailorMadePrice / (qtyRight * 2)}}
                </span>
                <span *ngIf="selectedTemplate == 'right'">
                  {{tailorMadePrice / qtyRight}}
                </span>
                <span *ngIf="selectedTemplate == 'left'">
                  {{tailorMadePrice / qtyLeft}}
                </span>
              </td>
              <td class="text-right">
                {{tailorMadePrice}}
               </td>
            </tr>
            <!-- / SERVICES -->
         </ng-container>
        <!-- / SERVICES -->
          <tr>
            <td>
              Additional
            </td>
            <td colspan="4">
              <input
              type="number"
              class="text-right w-100"
              (input)="valueChanged()"
              [(ngModel)]="additional"
              NumberValidate
            />
            </td>
          </tr>
          <tr>
            <td>
              Discont
            </td>
            <td colspan="4">
              <input
              type="number"
              class="text-right w-100"
              (input)="valueChanged()"
              [(ngModel)]="discount"
              NumberValidate
            />
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="leftArrayPush.length > 0 || rightArrayPush.length > 0 || servicesPush.length > 0">
          <tr>
            <td>Total </td>
            <td class="text-right" colspan="4">
              {{rightTotal + leftTotal + serviceTotal + additional - discount}}
              <!-- {{
                  lensePriceRight +
                  lensePriceLeft +
                  rightOval +
                  leftOval +
                  leftEtPrice +
                  rightEtPrice +
                  leftCtPrice +
                  rightCtPrice +
                  leftDecPrice +
                  rightDecPrice +
                  leftPrismPrice +
                  rightPrismPrice +
                  leftBaseCurvePrice +
                  rightBaseCurvePrice +
                  coatingPrice +
                  hcPrice +
                  selectedOtherServicePrice +
                  specialLogo +
                  engraveName +
                  mirrorPrice +
                  tintPrice +
                  fittingPrice +
                  tailorMadePrice +
                  additional -
                  discount
              }} -->
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
