import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit, OnChanges {
  @Input() priceSource: any;
  @Input() lensePriceRight: number = 0;
  @Input() lensePriceLeft: number = 0;
  @Input() coatingPrice: number = 0;
  @Input() tintPrice: number = 0;
  @Input() fittingPrice: number = 0;
  @Input() leftCtPrice: number = 0;
  @Input() leftEtPrice: number = 0;
  @Input() mirrorPrice: number = 0;
  @Input() leftBaseCurvePrice: number = 0;
  @Input() rightBaseCurvePrice: number = 0;
  @Input() rightEtPrice: number = 0;
  @Input() rightCtPrice: number = 0;
  @Input() rightPrismPrice: number = 0;
  @Input() leftDecPrice: number = 0;
  @Input() additionalPrice: number = 0;
  @Input() tailorMadePrice: number = 0;
  @Input() discountPrice: number = 0;
  @Input() selectedOtherServicePrice: number = 0;
  @Input() engraveName: number = 0;
  @Input() specialLogo: number = 0;
  @Input() hcPrice: number = 0;
  @Input() leftOval: number = 0;
  @Input() rightOval: number = 0;
  @Input() rightDecPrice: number = 0;
  @Input() leftPrismPrice: number = 0;
  @Input() selectedTemplate = '';
  @Output() valueChange = new EventEmitter();
  discount: any;
  additional: any;
  // tailor: any;
  constructor() {}

  ngOnInit() {
    // this.discount = this.discountPrice;
    // this.additional = this.additionalPrice;
    // this.tailor = this.priceSource.tailor;
    $(document).ready(() => {
      $('#dealInner').removeClass('hide');
      $('#close').addClass('hide');
      const countRightNav = $('.right-nav .card-body').height() + 10;
      const countHeight = '-' + countRightNav + 'px';
      $('.right-nav').css({ bottom: countHeight });
      $('#dealInner').on('click', () => {
        $('.right-nav')
          .stop()
          .animate({
            bottom: '4px',
            slow: '300'
          });
        $('#dealInner').addClass('hide');
        $('#close').removeClass('hide');
      });
      $('#close').on('click', () => {
        var r = $('.right-nav').height() - 50;
        $('.right-nav')
          .stop()
          .animate({
            bottom: '-' + r,
            slow: '300'
          });
        $('#dealInner').removeClass('hide');
        $('#close').addClass('hide');
      });
    });
  }

  valueChanged() { // You can give any function name
    this.valueChange.emit({discount: this.discount, additional: this.additional});
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('OnChanges', this.priceSource)
     this.discount = this.discountPrice;
    this.additional = this.additionalPrice;
  }

}
