<div class="filter-container">
  <form [formGroup]="filterForm">
    <div class="row">
      <div class="col-sm-{{colums}}" *ngIf="filterOptions?.brandMasterId">
        <div class="dx-field">
          <div>Brand:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="brandList" placeholder="Choose Brand" [showClearButton]="true" displayExpr="brandName"
            valueExpr="brandMasterId" [searchEnabled]="true" [readOnly]="brandList?.length == 0"
            formControlName="brandMasterId"
            (onSelectionChanged)="getAllSubBrandsByBrandMasterId(filterForm.get('brandMasterId').value)">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{colums}}" *ngIf="filterOptions?.subBrandMasterId">
        <div class="dx-field">
          <div>Sub Brand:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="subBrandList" placeholder="Choose Sub Brand" [showClearButton]="true"
            displayExpr="subBrandName" valueExpr="subBrandMasterId" [searchEnabled]="true"
            [readOnly]="subBrandList?.length == 0" formControlName="subBrandMasterId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{colums}}" *ngIf="filterOptions?.focalId">
        <div class="dx-field">
          <div>Focal:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="focalList" placeholder="Choose Focal" [showClearButton]="true" displayExpr="focalName"
            valueExpr="focalId" [searchEnabled]="true" [readOnly]="focalList?.length == 0" formControlName="focalId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{colums}}" *ngIf="filterOptions?.indexId">
        <div class="dx-field">
          <div>Index:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="indexList" placeholder="Choose Index" [showClearButton]="true" displayExpr="indexName"
            valueExpr="indexId" [searchEnabled]="true" [readOnly]="indexList?.length == 0" formControlName="indexId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{colums}}" *ngIf="filterOptions?.filterBrandId">
        <div class="dx-field" >
          <div>Filter Brands:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="filterBrandList" placeholder="Choose Filter Brands" [showClearButton]="true"
            displayExpr="filterName" valueExpr="filterBrandId" [searchEnabled]="true"
            [readOnly]="filterBrandList?.length == 0" formControlName="filterBrandId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{colums}}" *ngIf="filterOptions?.filterId">
        <div class="dx-field" >
          <div>Filter:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="filterList" placeholder="Choose Filter" [showClearButton]="true"
            displayExpr="filterName" valueExpr="filterId" [searchEnabled]="true"
            [readOnly]="filterList?.length == 0" formControlName="filterId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{colums}}" *ngIf="filterOptions?.coatingBrandId">
        <div class="dx-field">
          <div>Coating Brands:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="coatingBrandList" placeholder="Choose Coating Brands" [showClearButton]="true"
            displayExpr="coatingName" valueExpr="coatingBrandId" [searchEnabled]="true"
            [readOnly]="coatingBrandList?.length == 0" formControlName="coatingBrandId">
          </dx-select-box>
        </div>
      </div>
    </div>

    <div class="row"
      *ngIf="filterForm.get('brandMasterId').value || filterForm.get('subBrandMasterId').value || 
    filterForm.get('focalId').value || filterForm.get('indexId').value || filterForm.get('filterBrandId').value || filterForm.get('coatingBrandId').value || filterForm.get('filterId').value">
      <div class="col-sm-12">
        <dx-button id="button" text="Search" type="default" class="float-right" (click)="submitFilter(filterForm)">
        </dx-button>
      </div>
    </div>
  </form>
</div>