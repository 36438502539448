<mat-dialog-content>
<div fxLayout="column" class="mt-3 mb-3" *ngIf="list?.length > 0">
  <dx-data-grid
    id="gridContainer10"
    [dataSource]="list"
    keyExpr="rowIndex"
    [hoverStateEnabled]="true"
    [wordWrapEnabled]="true"
    [columnAutoWidth]="true"
    [showBorders]="true"
    [masterDetail]="{ enabled: false, template: 'detail' }"
    [columnHidingEnabled]="true"
  >
    <dxo-paging [enabled]="true" [pageSize]="40"></dxo-paging>
    <dxo-pager
      [showPageSizeSelector]="true"
      [allowedPageSizes]="[5, 10, 20]"
      [showInfo]="true"
    >
    </dxo-pager>
    <dxo-search-panel [visible]="false"></dxo-search-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxi-column
    dataField="itemCatalogGroupId"
    caption="GroupId"
    cssClass="text-center"
    [allowHeaderFiltering]="false"
    [width]="80"
  ></dxi-column>
    <dxi-column dataField="itemName" caption="Group Name"></dxi-column>
    <dxi-column
      dataField="materialName"
      caption="Material"
      [width]="150"
    ></dxi-column>
    <dxi-column
    dataField="focalName"
    caption="Focal"
    [width]="150"
  ></dxi-column>
    <dxi-column
      dataField="brandName"
      caption="Brand"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="subBrandName"
      caption="S.Brand"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="filterBrandName"
      caption="F.Brand"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="designTechName"
      [width]="150"
      caption="Design Tech"
    ></dxi-column>
    <dxi-column
    dataField="indexValue"
    caption="Index"
    [width]="150"
    cssClass="text-center"
  ></dxi-column>
  </dx-data-grid>
</div>
</mat-dialog-content>