<form [formGroup]="itemRangeForm">
  <div class="model-title" mat-dialog-title>
    <div>{{selectedRowData?.remarks}}</div>
  </div>
  <div mat-dialog-content>
    <!-- <div class="f-12 mb-2">
      <strong>{{selectedRowData?.powerRange}}</strong>
    </div> -->
    <div class="row">
      <div class="col-md-3">
        <div class="dx-field">
          <div>SPH Min<span>*</span>:</div>
          <dx-select-box
            [inputAttr]="{ autocomplete: 'off' }"
            class="enter-keypress-focus-input"
            formControlName="sphMin"
            [items]="sphRangeList"
            [readOnly]="sphRangeList?.length == 0"
            placeholder="{{loadingSphRange ? 'Please wait...' : 'Select SPH Min*'}}"
            displayExpr="sphValue"
            valueExpr="sphValue"
            [showClearButton]="true"
            [showDataBeforeSearch]="false"
            deferRendering="false"
            [searchEnabled]="true"
            (onSelectionChanged)="validateSPHMax(itemRangeForm.get('sphMin').value, itemRangeForm.get('sphMax').value)">
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="SPH Min is required"
            >
          </dxi-validation-rule>
         
          </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <div class="col-md-3">
        <div class="dx-field">
          <div>SPH Max<span>*</span>:</div>
          <dx-select-box
            [inputAttr]="{ autocomplete: 'off' }"
            class="enter-keypress-focus-input"
            formControlName="sphMax"
            [items]="sphRangeList"
            [readOnly]="sphRangeList?.length == 0"
            placeholder="{{loadingSphRange ? 'Please wait...' : 'Select SPH Max *'}}"
            displayExpr="sphValue"
            valueExpr="sphValue"
            [showClearButton]="true"
            [showDataBeforeSearch]="false"
            deferRendering="false"
            [searchEnabled]="true"
            (onSelectionChanged)="validateSPHMax(itemRangeForm.get('sphMin').value, itemRangeForm.get('sphMax').value)">
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="SPH Max is required"
            >
          </dxi-validation-rule>
          </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <!-- *ngIf="selectedRowData?.focalId == 1" -->
      <div class="col-md-3">
        <div class="dx-field">
          <div>CYL Min<span>*</span>:</div>
          <dx-select-box
            [inputAttr]="{ autocomplete: 'off' }"
            class="enter-keypress-focus-input"
            formControlName="cylMin"
            [items]="cylRangeList"
            [readOnly]="cylRangeList?.length == 0"
            placeholder="{{loadingCylRange ? 'Please wait...' : 'Select CYL Min*'}}"
            displayExpr="cylValue"
            valueExpr="cylValue"
            deferRendering="false"
            [showClearButton]="true"
            [showDataBeforeSearch]="false"
            [searchEnabled]="true"
            (onSelectionChanged)="validateCylMax(itemRangeForm.get('cylMin').value, itemRangeForm.get('cylMax').value)"
          >
          </dx-select-box>
        </div>
      </div>
      <!-- *ngIf="selectedRowData?.focalId == 1" -->
      <div class="col-md-3" >
        <div class="dx-field">
          <div>CYL Max<span>*</span>:</div>
          <dx-select-box
            [inputAttr]="{ autocomplete: 'off' }"
            class="enter-keypress-focus-input"
            formControlName="cylMax"
            [items]="cylRangeList"
            [readOnly]="cylRangeList?.length == 0"
            placeholder="{{loadingCylRange ? 'Please wait...' : 'Select CYL Max*'}}"
            displayExpr="cylValue"
            valueExpr="cylValue"
            deferRendering="false"
            [showClearButton]="true"
            [showDataBeforeSearch]="false"
            [searchEnabled]="true"
            (onSelectionChanged)="validateCylMax(itemRangeForm.get('cylMin').value, itemRangeForm.get('cylMax').value)"
          >
          </dx-select-box>
        </div>
      </div>
      <!-- *ngIf="(selectedRowData?.focalId == 2 || selectedRowData?.focalId == 3)" -->
      <div class="col-md-3">
        <div class="dx-field">
          <div>ADD<span>*</span>:</div>
          <dx-select-box
            [inputAttr]="{ autocomplete: 'off' }"
            class="enter-keypress-focus-input"
            formControlName="addMin"
            [items]="addfRangeList"
            [readOnly]="addfRangeList?.length == 0"
            placeholder="{{loadingAddfRange ? 'Please wait...' : 'Select ADD Min*'}}"
            displayExpr="addfValue"
            valueExpr="addfValue"
            [showClearButton]="true"
            [showDataBeforeSearch]="false"
            deferRendering="false"
            [searchEnabled]="true"
            (onSelectionChanged)="validateAddMax(itemRangeForm.get('addMin').value, itemRangeForm.get('addMax').value)"
          >
            <dx-validator>
              <dxi-validation-rule
                type="required"
                message="Add is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <!-- *ngIf="(selectedRowData?.focalId == 2 || selectedRowData?.focalId == 3)" -->
      <div class="col-md-3" >
        <div class="dx-field">
          <div>ADD<span>*</span>:</div>
          <dx-select-box
            [inputAttr]="{ autocomplete: 'off' }"
            class="enter-keypress-focus-input"
            formControlName="addMax"
            [items]="addfRangeList"
            [readOnly]="addfRangeList?.length == 0"
            placeholder="{{loadingAddfRange ? 'Please wait...' : 'Select ADD Max*'}}"
            displayExpr="addfValue"
            valueExpr="addfValue"
            [showClearButton]="true"
            [showDataBeforeSearch]="false"
            deferRendering="false"
            [searchEnabled]="true"
            (onSelectionChanged)="validateAddMax(itemRangeForm.get('addMin').value, itemRangeForm.get('addMax').value)"
          >
            <dx-validator>
              <dxi-validation-rule
                type="required"
                message="Add is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-12" *ngIf="itemRangeForm.get('sphMax').errors?.inValidPower">
        <div class="alert alert-danger">
          Min {{itemRangeForm.get('sphMin').value}} value greater max value {{itemRangeForm.get('sphMax').value}}
        </div>
      </div>
      <div class="col-sm-12" *ngIf="selectedRowData?.focalId == 1 && itemRangeForm.get('cylMax').errors?.inValidPower">
        <div class="alert alert-danger">
          Min {{itemRangeForm.get('cylMin').value}} value greater max value {{itemRangeForm.get('cylMax').value}}
        </div>
      </div>
      <div class="col-sm-12" *ngIf="(selectedRowData?.focalId == 2 || selectedRowData?.focalId == 3) && itemRangeForm.get('addMax').errors?.inValidPower">
        <div class="alert alert-danger">
          Min {{itemRangeForm.get('addMin').value}} value greater max value {{itemRangeForm.get('addMax').value}}
        </div>
      </div>
      <div class="col-md-12">
        <dx-button
        id="submit"
        [text]="'Add'"
        type="default"
        class="float-right"
        (click)="!itemRangeForm.valid || addItems(itemRangeForm)"
        [useSubmitBehavior]="true"
      >
      </dx-button>
      </div>
      
      
    </div>

    <div class="mt-5">
      <dx-data-grid
        #grid
        id="gridContainer"
        [dataSource]="cspRanges"
        [showBorders]="true"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="false"
      >
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-paging [enabled]="true"></dxo-paging>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-column-chooser [enabled]="true" mode="select">
          <!-- or "select" -->
        </dxo-column-chooser>
        <!-- <dxi-column
          dataField="itemName"
          [allowEditing]="false"
          caption="Item Name"
        ></dxi-column> -->
        <dxi-column
          dataField="customerName"
          [allowEditing]="false"
          caption="Customer"
          [visible]="true"
        ></dxi-column>
        <dxi-column dataField="sphMin" caption="SPH" cssClass="text-center" [width]="150"
                    [allowHeaderFiltering]="false" cellTemplate="sphTemplate" [visible]="true"></dxi-column>
                <dxi-column dataField="cylMin" caption="CYL" cssClass="text-center" [width]="150"
                    [allowHeaderFiltering]="false" cellTemplate="cylTemplate" [visible]="true"></dxi-column>
                <dxi-column dataField="addMin" caption="ADD" cssClass="text-center" [width]="150"
                    [allowHeaderFiltering]="false" cellTemplate="addTemplate" [visible]="true"></dxi-column>
       
        <dxi-column
          dataField="addDateTime"
          cssClass="text-center"
          [allowEditing]="false"
          caption="Date"
          [width]="120"
          [visible]="false"
        ></dxi-column>
        <div class="employee dx-row" *dxTemplate="let d of 'sphTemplate'" >
          <div class="text-center">
              <div *ngIf="d.data.sphMin != null && d.data.sphMax != null">
                  <mat-chip class="w-50 mr-1">{{d.data.sphMin }}</mat-chip>
                  <mat-chip class="w-50">{{d.data.sphMax }}</mat-chip>
              </div>
          </div>
       </div>
       <div class="employee dx-row" *dxTemplate="let d of 'cylTemplate'" >
          <div class="text-center">
              <div *ngIf="d.data.cylMin != null && d.data.cylMax != null">
                  <mat-chip class="w-50 mr-1">{{d.data.cylMin }}</mat-chip>
                  <mat-chip class="w-50">{{d.data.cylMax }}</mat-chip>
              </div>
          </div>
       </div>
       <div class="employee dx-row" *dxTemplate="let d of 'addTemplate'" >
          <div class="text-center">
              <div *ngIf="d.data.addMin != null && d.data.addMax != null">
                  <mat-chip class="w-50 mr-1">{{d.data.addMin }}</mat-chip>
                  <mat-chip class="w-50">{{d.data.addMax }}</mat-chip>
              </div>
          </div>
       </div>
      </dx-data-grid>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <dx-button text="Close" type="normal" class="mr-1" (click)="closePopup()">
    </dx-button>
 
  </mat-dialog-actions>
</form>
<!-- <h1 mat-dialog-title>
      <div class>{{selectedRowData?.itemName}}</div> 
      <div>
        {{selectedRowData?.powerRange}}
      </div>
    </h1>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6"></div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Add Range</button>
    </mat-dialog-actions> -->
