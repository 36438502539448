import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service"; // Import AuthService
import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import * as moment from "moment-timezone";

@Injectable({
  providedIn: "root",
})
export class HeaderInterceptorService {
  private axiosInstance: AxiosInstance;

  constructor(private authService: AuthService) {
    this.axiosInstance = this.createAxiosInstance();
    this.setupInterceptors();
  }

  private createAxiosInstance(): AxiosInstance {
    return axios.create(); // No baseURL since it's in environment files
  }

  private setupInterceptors(): void {
    this.axiosInstance.interceptors.request.use(
      this.requestInterceptor.bind(this),
      this.errorInterceptor.bind(this)
    );

    this.axiosInstance.interceptors.response.use(
      this.responseInterceptor.bind(this),
      this.errorInterceptor.bind(this)
    );
  }

  private async requestInterceptor(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    let token = localStorage.getItem("userToken"); // FIX: Ensure correct token key

    if (token) {
      if (!this.authService.isAuthenticated()) {
        console.warn("Token expired! Refreshing...");
        try {
          await this.authService.handleTokenRefresh();
          token = localStorage.getItem("userToken"); // Get updated token
        } catch (error) {
          console.error("Token refresh failed! Redirecting to login...");
          this.authService.logout();
          return Promise.reject(error);
        }
      }

      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${token}`; // FIX: Ensuring Authorization header is added
    }

    // Attach additional headers
    const userTimeZone = moment.tz.guess();
    try {
      const selectedCompanyBranch = JSON.parse(localStorage.getItem("selectedCustomerBranch") || "{}");
      const selectedUser = JSON.parse(localStorage.getItem("userData") || "{}");

      if (selectedCompanyBranch && selectedUser) {
        config.headers["CompanyId"] = selectedCompanyBranch.companyId;
        config.headers["CompanyBranchId"] = selectedCompanyBranch.companyBranchId;
        config.headers["UserId"] = selectedUser.userId;
        config.headers["UserDateTime"] = new Date().toISOString();
        config.headers["UserTimeZone"] = userTimeZone;
      }
    } catch (error) {
      console.error("Error parsing local storage items", error);
    }

    return config;
  }

  private responseInterceptor(response: AxiosResponse): AxiosResponse {
    return response;
  }

  private errorInterceptor(error: AxiosError): Promise<any> {
    if (error.response?.status === 401) {
      console.error("Unauthorized request! Redirecting to login...");
      this.authService.logout();
    }
    return Promise.reject(error);
  }

  // ✅ API Methods
  public get(endpoint: string, params?: any): Promise<any> {
    return this.axiosInstance.get(endpoint, { params });
  }

  public post(endpoint: string, data: any): Promise<any> {
    return this.axiosInstance.post(endpoint, data);
  }

  public put(endpoint: string, data: any): Promise<any> {
    return this.axiosInstance.put(endpoint, data);
  }

  public delete(endpoint: string): Promise<any> {
    return this.axiosInstance.delete(endpoint);
  }
}
