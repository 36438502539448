import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import axios from "axios";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class AuthService {
    constructor(public jwtHelper: JwtHelperService,private router: Router) {}
    // ...
    public isAuthenticated(): boolean {
        const token = localStorage.getItem('userToken');
        // Check whether the token is expired and return
        // true or false
        // return !this.jwtHelper.isTokenExpired(token);
        return token ? !this.jwtHelper.isTokenExpired(token) : false;
    }

    public async handleTokenRefresh(): Promise<void> {
      const refreshToken = localStorage.getItem("refreshToken");
  
      if (!refreshToken) {
        this.logout();
        return Promise.reject("No refresh token available");
      }
  
      try {
        const response = await axios.post("/auth/refresh-token", { refreshToken });
        const newToken = response.data.token;
        localStorage.setItem("userToken", newToken); // FIX: Store token correctly
      } catch (error) {
        console.error("Token refresh failed:", error);
        this.logout();
        return Promise.reject(error);
      }
    }

    public logout(): void {
      localStorage.removeItem("userToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userData");
      localStorage.removeItem("selectedCustomerBranch");
      this.router.navigate(["/authentication/login"]);
    }
}



