<div class="filter-container">
  <mat-chip-listbox #chipList aria-label="Filter Data" class="mb-3">
    <mat-chip *ngFor="let item of tempResult" [selectable]="selectable" [removable]="removable"
      (removed)="remove(item)">
      {{item.itemName }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
  </mat-chip-listbox>
  <form [formGroup]="filterForm" class="mt-3" >
    <!-- <ng-container *ngIf="filterbySubmitToggle"> -->
    <div class="row">
      <div class="col-sm-{{columsType.brandMasterId}}" *ngIf="filterOptions?.brandMasterId">
        <div class="dx-field">
          <div>Brand:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="brandList" placeholder="Choose Brand" [showClearButton]="true" displayExpr="brandName"
            valueExpr="brandMasterId" [searchEnabled]="true" [readOnly]="brandList?.length == 0"
            formControlName="brandMasterId"
            (onSelectionChanged)="getAllSubBrandsByBrandMasterId(filterForm.get('brandMasterId').value)">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{columsType.subBrandMasterId}}" *ngIf="filterOptions?.subBrandMasterId">
        <div class="dx-field">
          <div>Sub Brand:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="subBrandList" placeholder="Choose Sub Brand" [showClearButton]="true"
            displayExpr="subBrandName" valueExpr="subBrandMasterId" [searchEnabled]="true"
            [readOnly]="subBrandList?.length == 0" formControlName="subBrandMasterId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{columsType.focalId}}" *ngIf="filterOptions?.focalId">
        <div class="dx-field">
          <div>Focal:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="focalList" placeholder="Choose Focal" [showClearButton]="true" displayExpr="focalName"
            valueExpr="focalId" [searchEnabled]="true" [readOnly]="focalList?.length == 0" formControlName="focalId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{columsType.itemSizeId}}" *ngIf="filterOptions?.itemSizeId">
        <div class="dx-field">
          <div>Item Size:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="itemSizeList" placeholder="Choose Size" [showClearButton]="true" displayExpr="itemSizeValue"
            valueExpr="itemSizeId" [searchEnabled]="true" [readOnly]="itemSizeList?.length == 0" formControlName="itemSizeId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{columsType.colorMasterId}}" *ngIf="filterOptions?.colorMasterId">
        <div class="dx-field">
          <div>Color:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="colorList" placeholder="Choose Color" [showClearButton]="true" displayExpr="colorName"
            valueExpr="colorMasterId" [searchEnabled]="true" [readOnly]="colorList?.length == 0" formControlName="colorMasterId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{columsType.indexId}}" *ngIf="filterOptions?.indexId">
        <div class="dx-field">
          <div>Index:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="indexList" placeholder="Choose Index" [showClearButton]="true" displayExpr="indexName"
            valueExpr="indexId" [searchEnabled]="true" [readOnly]="indexList?.length == 0" formControlName="indexId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{columsType.filterBrandId}}" *ngIf="filterOptions?.filterBrandId">
        <div class="dx-field" >
          <div>Filter Brands:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="filterBrandList" placeholder="Choose Filter Brands" [showClearButton]="true"
            displayExpr="filterName" valueExpr="filterBrandId" [searchEnabled]="true"
            [readOnly]="filterBrandList?.length == 0" formControlName="filterBrandId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{columsType.filterId}}" *ngIf="filterOptions?.filterId">
        <div class="dx-field" >
          <div>Filter:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="filterList" placeholder="Choose Filter" [showClearButton]="true"
            displayExpr="filterName" valueExpr="filterId" [searchEnabled]="true"
            [readOnly]="filterList?.length == 0" formControlName="filterId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{columsType.coatingBrandId}}" *ngIf="filterOptions?.coatingBrandId">
        <div class="dx-field">
          <div>Coating Brands:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="coatingBrandList" placeholder="Choose Coating Brands" [showClearButton]="true"
            displayExpr="coatingName" valueExpr="coatingBrandId" [searchEnabled]="true"
            [readOnly]="coatingBrandList?.length == 0" formControlName="coatingBrandId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{columsType.customerId}}" *ngIf="filterOptions?.customerId">
        <div class="dx-field">
          <div>Customers:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="customerList" placeholder="Choose Customers" [showClearButton]="true"
            displayExpr="customerName" valueExpr="customerId" [searchEnabled]="true"
            [readOnly]="customerList?.length == 0" formControlName="customerId">
          </dx-select-box>
        </div>
      </div>
      
      <div class="col-sm-{{columsType.pendingStatusId}}" *ngIf="filterOptions?.pendingStatusId">
        <div class="dx-field">
          <div>Order Status:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="orderStatusList" placeholder="Choose Order Status"
              [showClearButton]="true" displayExpr="processName"
              valueExpr="pendingStatusId" [searchEnabled]="true"
              [readOnly]="orderStatusList?.length == 0"
              formControlName="pendingStatusId">
          </dx-select-box>
        </div>
      </div>
      <div class="col-sm-{{columsType.salesOrderTypeId}}" *ngIf="filterOptions?.salesOrderTypeId">
        <div class="dx-field">
          <div>Order Type:</div>
          <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="orderTypeList" placeholder="Choose Order Status"
              [showClearButton]="true" displayExpr="salesOrderTypeName"
              valueExpr="salesOrderTypeId" [searchEnabled]="true"
              [readOnly]="orderTypeList?.length == 0"
              formControlName="salesOrderTypeId"
              >
          </dx-select-box>
        </div>
      </div>
    </div>

    <div class="row"
      *ngIf="filterForm.get('brandMasterId').value || filterForm.get('subBrandMasterId').value || 
    filterForm.get('focalId').value || filterForm.get('indexId').value ||
     filterForm.get('filterBrandId').value || filterForm.get('coatingBrandId').value ||
      filterForm.get('filterId').value ||
      filterForm.get('itemSizeId').value ||
      filterForm.get('colorMasterId').value || filterForm.get('customerId').value
      || filterForm.get('pendingStatusId').value || filterForm.get('salesOrderTypeId').value">
      <div class="col-sm-12">
        <dx-button id="button" text="Search" type="default" class="float-right" (click)="submitFilter(filterForm)">
        </dx-button>
      </div>
    </div>
  <!-- </ng-container> -->
  </form>
</div>