import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[SignNotAllowed]'
})

export class SignNotAllowed{
@Input('SignNotAllowed') sign: any;
  constructor(private el:ElementRef){ }
  @HostListener('keypress', ['$event']) onkeypress(evt){

    if (evt.which == this.sign || evt.key == this.sign)
    {
        evt.preventDefault();
    }
    // if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57)
    // {
    //     evt.preventDefault();
    // }
  }
}
