<dx-load-panel
  [hideOnOutsideClick]="false"
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loadingIndicator" 
>
</dx-load-panel>
<!-- *ngIf="loadingIndicator; then templateContent" -->
<!-- <ng-template #templateContent> -->
<div class="form-grid-container mt-3">
  <div class="">
    <form [formGroup]="requestForm">
      <ng-container class="row mt-3" formArrayName="sections">
        <ng-container
          *ngFor="let section of sectionsArray.controls; let i = index"
        >
          <ng-container [formGroupName]="i">
            <!-- <dx-button text="Clear Range" type="danger" class="float-right" (click)="clearField()"
              *ngIf="!displayOnly"></dx-button> -->
            <mat-slide-toggle
              class="float-left"
              color="primary"
              (change)="toggleStock('previous')"
              *ngIf="showPreviousStock"
            >
              Previous Stock
            </mat-slide-toggle>
            <dx-button
              id="add-row"
              text="Add Rows"
              *ngIf="showAddRowBtn"
              type="normal"
              class="float-right"
              (click)="addRows()"
            >
            </dx-button>

            <span class="total-count" *ngIf="totalToggle">Total: {{getTotal}}</span>
            <div class="clearfix"></div>
            <div class="row mt-2" *ngIf="getCxTypeDetail(section).length > 0">
              <div class="col">
                <div class="card animated fadeIn">
                  <div class="card-body pb-0">
                    <div class="table-responsive-sm">
                      <ng-container formArrayName="cxTypeDetail">
                        <table
                          class="table table-striped use-equal-cell-width text-center table-with-over reset-tabindex"
                        >
                        <colgroup>
                          <col />
                          <col />
                          <col width="200px" />
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                        </colgroup>
                          <thead>
                            <tr>
                              <th>S#.</th>
                              <th *ngFor="let item of cxHeader">
                                {{ item.name }}
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              [formGroupName]="k"
                              *ngFor="
                                let item of getCxTypeDetail(section);
                                let k = index
                              "
                            >
                              <td>{{ k + 1 }}</td>
                              <td>
                                <select
                                  class="form-control-sm w-100"
                                  formControlName="cxtypeId" disabled 
                                >
                                  <option value="">Select Cx Type</option>
                                  <option
                                    *ngFor="let cx of cxList"
                                    [value]="cx.cxtypeId"
                                  >
                                    {{ cx.cxtypeName }}
                                  </option>
                                </select>
                              </td>
                             
                              <td *ngIf="!item.get('isBarCodeEnable').value">
                                <input
                                  type="text"
                                  formControlName="vendorItemCode"
                                  SignNotAllowed="-"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight w-200" [readonly]="readonly"
                                  autocomplete="off"
                                />
                              </td>
                              <td *ngIf="item.get('isBarCodeEnable').value">
                                <input
                                  type="text"
                                  formControlName="vendorItemCode"
                                  SignNotAllowed="-"
                                  
                                  (focusout)="getCatalogByBarcode(item.get('vendorItemCode').value, k)"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight w-200"
                                  autocomplete="off"
                                />
                              </td>
                              <td>
                                <input
                                  tabindex="{{ k + 1 }}"
                                  SignNotAllowed="{{ inputType ? '' : '101' }}"
                                  type="text"
                                  NumberValidate
                                  formControlName="qty"
                                  [readonly]="!item.get('vendorItemCode').value || acknowledgement"
                                  SignNotAllowed="-"
                                  min="0"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center"
                                  autocomplete="off"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  formControlName="cx"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center" [readonly]="readonly"
                                  autocomplete="off"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  min="0"
                                  SignNotAllowed="-"
                                  formControlName="tc"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center" [readonly]="readonly"
                                  autocomplete="off"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  min="0"
                                  SignNotAllowed="-"
                                  formControlName="cc"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center" [readonly]="readonly"
                                  autocomplete="off"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  min="0"
                                  SignNotAllowed="-"
                                  formControlName="ct"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center" [readonly]="readonly"
                                  autocomplete="off"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  min="0"
                                  SignNotAllowed="-"
                                  formControlName="et"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center" [readonly]="readonly"
                                  autocomplete="off"
                                />
                              </td>

                              <td>
                                <input
                                  type="number"
                                  min="0"
                                  SignNotAllowed="-"
                                  formControlName="add"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center" [readonly]="readonly"
                                  autocomplete="off"
                                />
                              </td>
                              <td *ngIf="showWt">
                                <input
                                  type="number"
                                  min="0"
                                  SignNotAllowed="-"
                                  formControlName="wt"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center"
                                  [readonly]="readonly"
                                  autocomplete="off"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id="comment-{{ k }}"
                                  (input)="showRemarks(section, k)"
                                  (focus)="showRemarks(section, k)"
                                  formControlName="remarks"
                                  [readonly]="readonly"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight"
                                  autocomplete="off"
                                />
                              </td>
                              <td>
                                <a
                                  href="javascript:void(0)"
                                  class="cursor-pointer warn"
                                >
                                  <mat-icon (click)="removeIndex(k)"
                                    >delete_forever</mat-icon
                                  >
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <dx-popover position="top" [width]="150" [showTitle]="true" title="Remarks Preview"
            [(visible)]="actionSheetVisible" [target]="popupTarget" [hideOnOutsideClick]="true">
            <div *dxTemplate="let data = model of 'content'">
              {{userRemarks}}
            </div>
          </dx-popover>
            <div class="row mt-2">
              <div class="col animated fadeInUp" *ngIf="validateSubmitBtn">
                <dx-button
                  id="button"
                  [disabled]="loadingIndicator"
                  [text]="isEdit ? 'Update' : 'Save'"
                  type="default"
                  class="float-right ml-3"
                  (click)="!requestForm.valid || saveStockRequest(requestForm)"
                  [useSubmitBehavior]="true"
                >
                </dx-button>
                <dx-button
                  text="Cancel"
                  type="normal"
                  class="float-right"
                  appBackButton
                >
                </dx-button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </form>
  </div>
</div>
<!-- </ng-template> -->